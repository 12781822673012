
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import './slides.css'
import './card.css'

function chunkArray(array, chunkSize) {
  const result = [];
  for (let i = 0; i < array.length; i += chunkSize) {
    result.push(array.slice(i, i + chunkSize));
  }
  return result;
}

function Home_page() {
  const [buildings, setBuildings] = useState([]);
  const [selectedCity, setSelectedCity] = useState("");  // Default to "All Cities"
  const [apartmentType, setApartmentType] = useState(""); // Default to "All Types"

  const displayedBuildings = buildings.slice(0, 3);
  useEffect(() => {
    fetch(`https://backendjaes.passiontrials.co.za/displayapartment`)  // Endpoint to fetch all apartments
      .then(response => response.json())
      .then(data => {
        //  setBuildings(data); // Assume the data is an array of apartments
      })
      .catch(error => console.error("Error loading all buildings:", error));

    buildingInfo();
  }, []);

  const buildingInfo = () => {
    fetch(`https://backendjaes.passiontrials.co.za/new_building_tb`)
      .then(res => res.json())
      .then(info => {
        setBuildings(info);
        console.log('this is the data that must be revealed', info);
      })
      .catch(error => {
        console.log('An error occurred', error);
      })
  };

  const handleGender = (binary) => {
    if (binary) {
      if (binary == 0) {
        return 'Female';
      }

      if (binary == 1) {

        return 'Male';
      }

      if (binary == 2) {
        return 'Female | Male';
      }
    }
  }
  return (
    <body>
      <section class="slider_container">
        <section class="slider">
          <div class="slide one">
            <img src="https://jaes.co.za/wp-content/uploads/2024/01/IMG_20230226_113005-scaled.jpg" alt="" />
            <span class="caption"> Home one </span>
          </div>
          <div class="slide two">
            <img src="https://backendjaes.passiontrials.co.za/src/Building_images/Bartsplace.jpeg" alt="" />
            <span class="caption"> Barts Place</span>
          </div>
          <div class="slide three">
            <img src="https://jaes.co.za/wp-content/uploads/2024/01/IMG_20230226_113005-scaled.jpg" alt="" />
            <span class="caption"> Home three </span>
          </div>
          <div class="slide four">
            <img src="https://jaes.co.za/wp-content/uploads/2024/01/IMG_20230226_113005-scaled.jpg" alt="" />
            <span class="caption"> Home four </span>
          </div>
          <div class="slide four">
            <img src="https://jaes.co.za/wp-content/uploads/2024/01/IMG_20230226_113005-scaled.jpg" alt="" />
            <span class="caption"> Home five </span>
          </div>
        </section>
      </section>

      <section class="w3l-aboutblock py-lg-5 py-4" id="about">
        <div class="container">
          <div class="row align-items-center">
            <div class="col-lg-6">
              <img src="https://jaes.co.za/wp-content/uploads/2024/01/IMG_2865-scaled.jpg" alt="" class="img-fluid radius-image" />
            </div>

            <div class="col-lg-6 ps-xl-5 ps-lg-4 mt-lg-0 mt-5 text-start">
              <div class="title-main">
                <p>About Us</p>
                <h3 class="title-style">Plan Your Trip with Tours</h3>
              </div>
              <p class="mt-3">Lorem ipsum viverra feugiat. Pellen tesque libero ut justo,
                ultrices in ligula. Semper at tempufddfel. Lorem ipsum dolor sit amet consectetur adipisicing
                elit.</p>
              <ul class="mt-4 list-style-lis">
                <li><i class="fas fa-check-circle"></i>2000+ Our Worldwide Guide</li>
                <li class="mt-2"><i class="fas fa-check-circle"></i>100% Trusted Tour Agency</li>
                <li class="mt-2"><i class="fas fa-check-circle"></i>24+ Years of Experience</li>
                <li class="mt-2"><i class="fas fa-check-circle"></i>100% Travelers are Happy</li>
              </ul>
              <a href="/Building" class="btn btn-style mt-5">Booking Now</a>
            </div>
          </div>
        </div>
      </section>

      <section class="w3l-index5 pb-5 pt-4">
        <div class="container-fluid py-lg-5 py-md-4 py-2">
          <div class="title-main text-center mx-auto mb-md-5 mb-4" style={{ maxWidth: '600px' }}>
            <p>Featured tours</p>
            <h3 class="title-style">Most Popular Tours</h3>
          </div>
          <div className="room-card-container">
            {displayedBuildings.map((build) => (
              <Link to={`/Apartments/${build.BuildingId}`} className="view-link" key={build.BuildingId}>
                <div className="room-card">
                  <img src={`https://backendjaes.passiontrials.co.za/src${build.BuildingExteriorImage4}`} alt="Building Exterior" className="room-card-image" />
                  <div className="room-card-content">
                    <h2 className="room-title">{build.BuildingName}</h2>
                    <p className="b-type"><i className="fas fa-map-marker-alt me-1"></i>{build.BuildingLocation}</p>
                    <div className="plan-options">
                      <button className="plan-option">{handleGender(build.PreferedGender)}</button>
                    </div>
                    <div className="room-details">
                      <div className="detail-item">
                        <p>Building Capacity</p>
                        <span>{build.TotalCapacity}</span>
                      </div>
                      <div className="detail-item">
                        <p>Zone</p>
                        <span>{build.Zone}</span>
                      </div>
                      <div className="detail-item">
                        <p>Beds Available</p>
                        <span>{build.Capacity}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            ))}
          </div>
          {/* Add button to navigate to all buildings */}
          <div className="text-center mt-4">
            <Link to="/Building">
              <button className="btn btn-style">View All Buildings</button>
            </Link>
          </div>
        </div>
      </section>
      <section class="w3_stats py-5" id="stats">
        <div class="container py-lg-5 py-md-4 py-2">
          <div class="title-main text-center mx-auto mb-5" style={{ maxWidth: '600px' }}>
            <p>Statistics</p>
            <h3 class="title-style text-white">We have over 10 years Experience</h3>
          </div>
          <div class="w3-stats text-center py-lg-4">
            <div class="row">
              <div class="col-md-3 col-6">
                <div class="counter">
                  <i class="fas fa-route"></i>
                  <div class="d-flex align-items-center justify-content-center">
                    <div class="timer" data-to="200" data-speed="1500"></div>
                    <span class="stats-plus">+</span>
                  </div>
                  <p class="count-text">Total Destinations</p>
                </div>
              </div>
              <div class="col-md-3 col-6">
                <div class="counter">
                  <i class="fas fa-smile"></i>
                  <div class="d-flex align-items-center justify-content-center">
                    <div class="timer" data-to="100" data-speed="1500"></div>
                    <span class="stats-plus">+</span>
                  </div>
                  <p class="count-text">Happy People</p>
                </div>
              </div>
              <div class="col-md-3 col-6 mt-md-0 mt-4">
                <div class="counter">
                  <i class="fas fa-medal"></i>
                  <div class="d-flex align-items-center justify-content-center">
                    <div class="timer" data-to="30" data-speed="1500"></div>
                    <span class="stats-plus">+</span>
                  </div>
                  <p class="count-text">Awards Won</p>
                </div>
              </div>
              <div class="col-md-3 col-6 mt-md-0 mt-4">
                <div class="counter">
                  <i class="fas fa-umbrella-beach"></i>
                  <div class="d-flex align-items-center justify-content-center">
                    <div class="timer" data-to="130" data-speed="1500"></div>
                    <span class="stats-plus">+</span>
                  </div>
                  <p class="count-text">Stunning Places</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="w3l-grids-block py-5">
        <div class="container py-lg-5 py-md-4 py-2">
          <div class="title-main text-center mx-auto mb-md-5 mb-4" style={{ maxWidth: '600px' }}>
            <p>Our Services</p>
            <h3 class="title-style">Our Amazing Services for your Travel</h3>
          </div>
          <div class="row text-center justify-content-center">
            <div class="col-lg-4 col-md-6 col-sm-10">
              <div class="bottom-block">
                <a href="tours.html" class="d-block">
                  <i class="fas fa-dragon"></i>
                  <h3 class="my-3">Wildlife Tours</h3>
                  <p class="">Lorem ipsum dolor sit amet consectetur adipisicing elit. Laudantium tempora
                    rerum perspiciatis?</p>
                </a>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-10 mt-md-0 mt-4">
              <div class="bottom-block">
                <a href="tours.html" class="d-block">
                  <i class="fas fa-plane-departure"></i>
                  <h3 class="my-3">Adventure Tours</h3>
                  <p class="">Lorem ipsum dolor sit amet consectetur adipisicing elit. Laudantium tempora
                    rerum perspiciatis?</p>
                </a>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-10 mt-lg-0 mt-4">
              <div class="bottom-block">
                <a href="tours.html" class="d-block">
                  <i class="fab fa-discourse"></i>
                  <h3 class="my-3">Trip Planing</h3>
                  <p class="">Lorem ipsum dolor sit amet consectetur adipisicing elit. Laudantium tempora
                    rerum perspiciatis?</p>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="w3l-whyblock pb-5 pt-2">
        <div class="container pb-lg-5 pb-md-4 pb-2">
          <div class="row align-items-center">
            <div class="col-lg-6">
              <img src="assets/images/about2.jpg" alt="" class="img-fluid radius-image" />
            </div>
            <div class="col-lg-6 ps-xl-5 ps-lg-4 mt-lg-0 mt-5">
              <div class="title-main mb-4" style={{ maxWidth: '600px' }}>
                <p>Our Features</p>
                <h3 class="title-style">Why Choose Tours!</h3>
              </div>
              <p>Lorem ipsum viverra feugiat. Pellen tesque libero ut justo,
                ultrices in ligula. Semper at tempufddfel. Lorem ipsum dolor sit amet consectetur adipisicing
                elit.</p>
              <div class="two-grids mt-5">
                <div class="grids_info">
                  <i class="fas fa-certificate"></i>
                  <div class="detail">
                    <h4>Professional and Certified</h4>
                    <p>Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt
                      mollit.</p>
                  </div>
                </div>
                <div class="grids_info mt-xl-5 mt-4">
                  <i class="fas fa-money-bill-wave"></i>
                  <div class="detail">
                    <h4>Get Instant Tour Bookings</h4>
                    <p>Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt
                      mollit.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div class="w3l-blog-content py-5">
        <div class="container py-lg-5 py-md-4 py-2">
          <div class="title-main text-center mx-auto mb-md-5 mb-4" style={{ maxWidth: '600px' }}>
            <p>Our Blog</p>
            <h3 class="title-style">Learn more from our latest Blog Posts</h3>
          </div>
          <div class="row justify-content-center">
            <div class="col-lg-4 col-md-6">
              <div class="blog-grid-1">
                <div class="card-header p-0 position-relative">
                  <a href="#blog" class="zoom d-block">
                    <img class="card-img-bottom d-block" src="assets/images/blog1.jpg" alt="Card image cap" />
                  </a>
                  <div class="course-price-badge">Trips</div>
                </div>
                <div class="card-body course-details">
                  <div class="course-meta mb-3">
                    <div class="meta-item course-students">
                      <a href="#author"><span class="fas fa-user"></span>
                        <span class="meta-value"></span>John</a>
                    </div>
                    <div class="meta-item course-lesson">
                      <span class="fa fa-heart"></span>
                      <span class="meta-value"> 23 </span>
                    </div>
                    <div class="meta-item course-students">
                      <span class="fa fa-calendar"></span>
                      <span class="meta-value">Dec 06, 2021</span>
                    </div>
                  </div>
                  <a href="#blog" class="course-desc">Travel the Most Beautiful Places in the
                    World</a>
                  <p class="mt-2">Lorem ipsum dolor sit, amet consectetur elit. Earum mollitia
                    ipsam autem.</p>
                  <a href="#blog" class="btn btn-style mt-4">Read more</a>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 mt-md-0 mt-4">
              <div class="blog-grid-1">
                <div class="card-header p-0 position-relative">
                  <a href="#blog" class="zoom d-block">
                    <img class="card-img-bottom d-block" src="assets/images/blog2.jpg" alt="Card image cap" />
                  </a>
                  <div class="course-price-badge">Travels</div>
                </div>
                <div class="card-body course-details">
                  <div class="course-meta mb-3">
                    <div class="meta-item course-students">
                      <a href="#author"><span class="fas fa-user"></span>
                        <span class="meta-value"></span>Anton</a>
                    </div>
                    <div class="meta-item course-lesson">
                      <span class="fa fa-heart"></span>
                      <span class="meta-value"> 24 </span>
                    </div>
                    <div class="meta-item course-students">
                      <span class="fa fa-calendar"></span>
                      <span class="meta-value">Dec 07, 2021</span>
                    </div>
                  </div>
                  <a href="#blog" class="course-desc">A Place where Start New Life with Peace</a>
                  <p class="mt-2">Lorem ipsum dolor sit, amet consectetur elit. Earum mollitia
                    ipsam autem.</p>
                  <a href="#blog" class="btn btn-style mt-4">Read more</a>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 mt-lg-0 mt-4">
              <div class="blog-grid-1">
                <div class="card-header p-0 position-relative">
                  <a href="#blog" class="zoom d-block">
                    <img class="card-img-bottom d-block" src="assets/images/blog3.jpg" alt="Card image cap" />
                  </a>
                  <div class="course-price-badge">Journey</div>
                </div>
                <div class="card-body course-details">
                  <div class="course-meta mb-3">
                    <div class="meta-item course-students">
                      <a href="#author"><span class="fas fa-user"></span>
                        <span class="meta-value"></span>Miche</a>
                    </div>
                    <div class="meta-item course-lesson">
                      <span class="fa fa-heart"></span>
                      <span class="meta-value"> 22 </span>
                    </div>
                    <div class="meta-item course-students">
                      <span class="fa fa-calendar"></span>
                      <span class="meta-value">Dec 08, 2021</span>
                    </div>
                  </div>
                  <a href="#blog" class="course-desc">Top 10 Destinations & Adventure Trips</a>
                  <p class="mt-2">Lorem ipsum dolor sit, amet consectetur elit. Earum mollitia
                    ipsam autem.</p>
                  <a href="#blog" class="btn btn-style mt-4">Read more</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section class="w3l-promocode py-5">
        <div class="container ppy-lg-5 py-md-4 py-2">
          <div class="row aap-4-section align-items-center">
            <div class="col-lg-6 col-8 m-auto app4-right-image pe-md-5 text-center">
              <img src="assets/images/img.jpg" class="img-fluid radius-image" alt="App Device" />
            </div>
            <div class="col-lg-6 app4-left-text ps-lg-0 mb-lg-0 mb-5">
              <h6>For 30% Discount</h6>
              <h4>Get Our Promocode</h4>
              <p class="mb-4"> Uspendisse efficitur orci urna. In et augue ornare, tempor massa in, luctus
                sapien. Proin a
                diam et dui fermentum molestie vel id neque. </p>
              <div class="app-4-connection">
                <div class="newsletter">
                  <label>Never Miss a Deal !</label>
                  <form action="#" methos="GET" class="d-flex wrap-align">
                    <input type="email" placeholder="Enter your email id" required="required" />
                    <button type="submit" class="button-style">Get Promocode</button>
                  </form>
                </div>
                <p class="mobile-text-app mt-4 pt-2">(Or) To Get Our Mobile Apps</p>
                <div class="app-4-icon">
                  <ul>
                    <li><a href="#url" title="Apple" class="app-icon apple-vv"><span
                      class="fab fa-apple  icon-color" aria-hidden="true"></span></a></li>
                    <li><a href="#url" title="Google play" class="app-icon play-vv"><span
                      class="fab fa-google-play icon-color" aria-hidden="true"></span></a>
                    </li>
                    <li><a href="#url" title="Microsoft" class="app-icon windows-vv"><span
                      class="fab fa-windows icon-color" aria-hidden="true"></span></a></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <footer class="w3l-footer-29-main py-5">
        <div class="container pt-lg-5 pt-sm-4 pt-2 pb-2">
          <div class="row footer-top-29">
            <div class="col-lg-4 col-md-6 footer-list-29">
              <h6 class="footer-title-29">Contact Info </h6>
              <p class="mb-2 pe-xl-5">Address : Tours, 10001, 5th Avenue, #06 lane street, NY - 62617.
              </p>
              <p class="mb-2">Phone Number : <a href="tel:+1(21) 234 4567">+1(21) 234 4567</a></p>
              <p class="mb-2">Email : <a href="mailto:info@example.com">info@example.com</a></p>
              <div class="mt-4">
                <ul class="social">
                  <li><a href="#facebook"><i class="fab fa-facebook-f"></i></a>
                  </li>
                  <li><a href="#linkedin"><i class="fab fa-linkedin-in"></i></a>
                  </li>
                  <li><a href="#twitter"><i class="fab fa-twitter"></i></a>
                  </li>
                  <li><a href="#google"><i class="fab fa-google-plus-g"></i></a>
                  </li>
                  <li><a href="#github"><i class="fab fa-github"></i></a>
                  </li>
                </ul>
              </div>
            </div>
            <div class="col-lg-2 col-md-3 col-6 footer-list-29 mt-md-0 mt-4">
              <ul>
                <h6 class="footer-title-29">Quick Links</h6>
                <li><a href="about.html">About Us</a></li>
                <li><a href="#services">Services</a></li>
                <li><a href="#workwithus">Work with us</a></li>
                <li><a href="contact.html">Contact Us</a></li>
                <li><a href="#rewards">Rewards</a></li>
              </ul>
            </div>
            <div class="col-lg-2 col-md-3 col-6 ps-lg-5 ps-lg-4 footer-list-29 mt-md-0 mt-4">
              <ul>
                <h6 class="footer-title-29">Explore</h6>
                <li><a href="#blog">Blog Posts</a></li>
                <li><a href="#privacy">Privacy policy</a></li>
                <li><a href="#partners">Our Partners</a></li>
                <li><a href="#license">License & uses</a></li>
                <li><a href="#events">Events</a></li>
              </ul>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-8 footer-list-29 mt-lg-0 mt-4 ps-lg-5">
              <h6 class="footer-title-29">Subscribe</h6>
              <form action="#" class="subscribe" method="post">
                <input type="email" name="email" placeholder="Email Address" required="" />
                <button class="btn btn-style mt-2">Subscribe</button>
              </form>
              <p class="mt-3">Subscribe to our mailing list and get updates to your email inbox.</p>
            </div>
          </div>
          <p class="copy-footer-29 text-center mt-5 pt-lg-3">© 2021 Tours. All rights reserved. Design
            by <a href="https://w3layouts.com/" target="_blank">W3Layouts</a></p>
        </div>
      </footer>
    </body>
  );
}
export default Home_page;
