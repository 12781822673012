import { useParams, Link, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import './card.css';
import './apartment.css';

function Apartments() {
    const { buildingId } = useParams(); // Get buildingId from the URL
    const [apartments, setApartments] = useState([]);
    const [building, setBuilding] = useState(null); // To store building info, including image
    const [gender, setGender] = useState("");
    const [apartmentType, setApartmentType] = useState("");
    const [maxPrice, setMaxPrice] = useState("");
    const navigate = useNavigate();

    useEffect(() => {
        fetchBuildingInfo();
        fetchApartments();
        window.scrollTo(0, 190);
    }, [buildingId, gender, apartmentType, maxPrice]);

    const fetchBuildingInfo = () => {
        // Fetch the building information using the buildingId
        fetch(`https://backendjaes.passiontrials.co.za/building_image/${buildingId}`)
            .then((response) => response.json())
            .then((data) => {
                setBuilding(data); // Set building info (including the image)

            })

            .catch((error) => console.error('Failed to load building info:', error));
    };

    const fetchApartments = () => {
        let url = `https://backendjaes.passiontrials.co.za/apartment_tb/${buildingId}?`;
        if (gender) url += `gender=${gender}&`;
        if (apartmentType) url += `apartmentType=${apartmentType}&`;
        if (maxPrice) url += `maxPrice=${maxPrice}&`;

        fetch(url)
            .then(response => response.json())
            .then(data => {
                setApartments(data.data);
            })
            .catch(error => console.error('Failed to load apartments:', error));
    };

    const handleGender = (binary) => {
        if (binary) {
            if (binary == 0) {
                return 'Female';
            }
            if (binary == 1) {
                return 'Male';
            }
            if (binary == 2) {
                return 'Male | Female';
            }
        }
    };

    const handleNextPage = (id) => {
        navigate(`/ApartmentDetails/${id}`);
    };

    return (
        <div style={{ marginTop: '120px', backgroundColor: 'white' }}>
            {/* Breadcrumb Section */}
            <div className="breadcrumb-container">
                <div className="header-container">
                    <div className="header-text">{building?.BuildingName}</div>
                    <div className="subheader-text">{building?.BuildingLocation}</div>
                </div>
                <div className="breadcrumb">
                    <Link to="/"> Home </Link> <span> / </span>
                    <Link to="/Buildings"> Properties </Link> <span> / </span>
                    {building?.BuildingName}
                </div>
            </div>

            {/* Display the Building Image */}
            <div className="building-images-container">
                {building && (
                    <div className="main-image-container">
                        {/* First (big) building image */}
                        <img
                            src={`https://backendjaes.passiontrials.co.za/src${building.BuildingExteriorImage4}`}
                            alt={building.BuildingName}
                            className="big-building-image"
                        />
                    </div>
                )}

                {/* Two smaller images stacked on top of each other */}
                <div className="small-images-container">
                    {building && (
                        <>
                            <img
                                src={`https://backendjaes.passiontrials.co.za/src${building.BuildingExteriorImage4}`}
                                alt={`${building.BuildingName} view 1`}
                                className="small-building-image"
                            />
                            <img
                                src={`https://backendjaes.passiontrials.co.za/src/Building_images/Bartsplace_shower.jpeg`}
                                alt={`${building.BuildingName} view 2`}
                                className="small-building-image"
                            />
                        </>
                    )}
                </div>
            </div>


            {/* Filter Section */}
            <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '20px' }}>
                <div className="filter-section" style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                    <div className="filter-item">
                        <label>Gender:</label>
                        <select value={gender} onChange={(e) => setGender(e.target.value)}>
                            <option value="">All Genders</option>
                            <option value="0">Female</option>
                            <option value="1">Male</option>
                            <option value="2">Male | Female</option>
                        </select>
                    </div>

                    <div className="filter-item">
                        <label>Apartment Type:</label>
                        <select value={apartmentType} onChange={(e) => setApartmentType(e.target.value)}>
                            <option value="">All Types</option>
                            <option value="One Bedroom">One Bedroom</option>
                            <option value="Single with Ensuite">Single with Ensuite</option>
                            <option value="Sharing Double">Sharing Double</option>
                            <option value="Sharing Double with Ensuite">Sharing Double with Ensuite</option>
                            <option value="Sharing Triple">Sharing Triple</option>
                            <option value="Bachelor Pods">Bachelor Pods</option>
                        </select>
                    </div>

                    <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                        <div className="filter-item">
                            <label>Max Price:</label>
                            <input
                                type="number"
                                value={maxPrice}
                                onChange={(e) => setMaxPrice(e.target.value)}
                                placeholder="Enter max price"
                            />
                        </div>

                        <button onClick={fetchApartments} style={{ height: '38px' }}>Apply Filters</button>
                    </div>
                </div>
            </div>

            {/* Apartments Listing */}
            <div className="room-card-container">
                {apartments.length > 0 ? (
                    apartments
                        .filter(property => property.Capacity2 !== 0)  // Filter out apartments with Capacity2 == 0
                        .map((property, index) => (
                            <div className="room-card" key={index}>
                                <img src={`https://backendjaes.passiontrials.co.za/src${property.ApartmentRoomImage}`} alt="Room" className="room-card-image" />
                                <div className="room-card-content" onClick={() => handleNextPage(property.ApartmentId)}>
                                    <h2 className="room-title">{property.ApartmentNumber}</h2>
                                    <p className="room-type">{property.LivingArrangement}</p>
                                    <div className="plan-options">
                                        <button className="plan-option">PLAN A | R {property.ApartmentPrice}</button>
                                        <button className="plan-option" style={{ marginLeft: '10px' }}>PLAN B | R {property.PriceB}</button>
                                    </div>
                                    <a href="#" className="view-link">Click here to view</a>
                                    <div className="room-details">
                                        <div className="detail-item">
                                            <p>Gender</p>
                                            <span>{handleGender(property.ApartmentPreferedGender)}</span>
                                        </div>
                                        <div className="detail-item">
                                            <p>Capacity</p>
                                            <span>{property.TotalNumOfBeds}</span>
                                        </div>
                                        <div className="detail-item">
                                            <p>Available Beds</p>
                                            <span>{property.Capacity2}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))
                ) : (
                    <p>Loading properties...</p>
                )}
            </div>
        </div>
    );
}

export default Apartments;
