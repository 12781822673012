import React, { useState, useEffect } from 'react';
import { useAuth } from './AuthProvider';
//import { set } from 'react-datepicker/dist/date_utils';
import './userPayment.css';
import { useNavigate } from 'react-router-dom';

function UserPayment() {
    const [payment, setPayment] = useState([]);
    const { userId } = useAuth();
    const [error, setError] = useState(null);
    const navigate = useNavigate();


    useEffect(() => {

        if (userId) {

            fetch(`https://backendjaes.passiontrials.co.za/user_invoices/${userId}`, {
                headers: { 'Content-Type': 'application/json' }
            })
                .then(res => {
                    if (!res.ok) { throw new Error('Failed to get user Invoices'); }
                    return res.json();
                })
                .then(invoices => {
                    if (invoices) {
                        setPayment(invoices);
                        console.log('the invoices', invoices);


                    }
                    else {
                        console.log('No invoices found for this user');
                    }
                })
                .catch(err => {
                    console.error('Error:', err);
                    setError(err.message);
                });
        }


    }, [userId])

    return (
        <div className="container" style={{ marginTop: '149px' }}>
            <br />
            <h2>User Payments</h2>
            <br />
            {error && <p className='error'>{error}</p>}
            <table className='payment-table'>
                <thead>
                    <tr>
                        <th>INVOICE</th>
                        <th>CLIENT</th>
                        <th>AMOUNT</th>
                        <th>DATE OF INVOICE</th>
                        <th>NEXT BILLING DATE</th>
                        <th>ADVANCE PAY DATE</th>
                        <th>STATUS</th>
                        <th>ACTIONS</th>
                    </tr>
                </thead>
                <tbody>
                    {payment.map(pay => (
                        <tr key={pay.PayId}>
                            <td>#{pay.PayId}</td>
                            <td>
                                <div className='client-info'>
                                    <img src={pay.clientImage || 'https://picsum.photos/200/300?random=40'} alt='Client' className='client-image' />
                                    <div>
                                        <strong> {pay.Name} {pay.Surname}</strong><br />
                                        {pay.Email || 'Email'}
                                    </div>
                                </div>
                            </td>
                            <td className='amount'>R{pay.PayAmount}</td>
                            <td className='date'>{pay.PayDate}</td>
                            <td className='date'>{pay.PayNextDate}</td>
                            <td className='date'>{pay.PaidInAdvanceDate}</td>
                            <td className='status'>
                                <span className={`status ${pay.PaymentStatus.toLowerCase()}`}>
                                    {pay.PaymentStatus}
                                </span>
                            </td>
                            <td className='actions'>
                                <button className='view-button' onClick={() => navigate(`/userInvoice/${pay.PayId}`)} >View</button>
                                <button className='edit-button'>⬇</button>
                            </td>


                        </tr>
                    ))
                    }</tbody>
            </table>
        </div>
    )

}


function singleInvoice() {

    return <div>
        <p>this must have a invoice </p>
    </div>
}
export default UserPayment;