import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';


function AdminApartments() {

    const [apartment, setApartment] = useState([]);
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        fetch(`https://backendjaes.passiontrials.co.za/admin_side_apartment`, {
            headers: { 'Content-Type': 'application/json' }
        }).then(res => res.json())
            .then(data => {
                console.log(data);
                setApartment(data);
            })
            .catch(err => setError(err.message));
    }, []);



    return (
        <div className='container'>
            <br />
            <h2>Apartments</h2>
            {error && <p className='error'></p>}
            <table className='payment-table'>
                <thead>
                    <tr>
                        <th>Apartment ID</th>

                        <th>Price per Month</th>
                        <th>No. of Beds</th>
                        <th>BUILDING NAME</th>
                        <th>Type of Apartment</th>
                        <th>Preferred Room Gender</th>
                        <th>City</th>
                        <th>STATUS</th>
                        <th>ACTION</th>

                    </tr>
                </thead>
                <tbody>
                    {apartment.map(invo => (
                        <tr key={invo.ApartmentId}>
                            <td>#{invo.ApartmentId}</td>

                            <td className='amount'>R{invo.ApartmentPrice}</td>
                            <td className='date'>{invo.TotalNumOfBeds}</td>
                            <td className='amount'>{invo.BuildingName}</td>
                            <td className='amount'>{invo.ApartmentType}</td>
                            <td className='amount'>{invo.ApartmentPreferedGender}</td>
                            <td className='amount'>{invo.BuildingCity}</td>
                            {/* <td className='amount'>
                                <span className={`status ${invo.Payment.toLowerCase()} `}>
                                    {invo.PaymentStatus}
                                </span>
                            </td> */}
                            <td className='amount'>Not determined yet</td>
                            <td className='actions'>
                                <button className='view-button' onClick={() => navigate(`/adminApartmentDetails/${invo.ApartmentId}`)}>View</button>
                                <button className='edit-button'>⬇</button>
                            </td>

                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    )


}

export default AdminApartments;