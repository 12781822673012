import React from 'react';
import styled from 'styled-components';
import Countdown from 'react-countdown';

const mainBackgroundImage = 'url(https://images.unsplash.com/photo-1464655646192-3cb2ace7a67e?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D)';
const countdownBackgroundImage = "url(https://plus.unsplash.com/premium_photo-1683769251695-963095b23d67?q=80&w=870&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D)";

const Container = styled.div`
//   display: flex;
//   flex-direction: row;
//   justify-content: space-between;
//   background-color: #2a2a72; // Dark blue background
//   color: white;
//   min-height: 100vh; // Take full height of the viewport
//   padding: 50px;
//   box-sizing: border-box;
//   	margin-bottom: 143px;
//     margin-top:160px;
display: flex;
  justify-content: center; // Centers the card horizontally
  align-items: center; // Centers the card vertically
  background-color: #2a2a72; // Dark blue background
  color: white;
  min-height: 80vh; // Less height to appear more like a card
  margin: 10vh auto; // Adds vertical spacing and auto centers horizontally
  width: 80%; // Limits width to make it look like a card
  padding: 20px;
  box-sizing: border-box;
  border-radius: 8px; // Rounded corners for the card
  box-shadow: 0 8px 16px rgba(0,0,0,0.2); 
  background-image : ${mainBackgroundImage};
  background-size:cover;
  background-position:center;
    position: relative;
  
`;

const Content = styled.div`
//   display: flex;
//   flex-direction: column;
//   justify-content: center;
//   flex: 1;
//   padding-right: 50px; // Spacing between content and countdown

 flex: 1;
  padding-right: 50px; // Right padding for spacing between elements
  z-index:2;
`;

const Headline = styled.h1`
  font-size: 3rem; // Larger text
  margin: 0 0 20px 0;
`;

const Subtext = styled.p`
  margin: 0 0 20px 0;
`;

const Button = styled.button`
  padding: 10px 20px;
  margin-top: 10px;
  background-color: #ff5a5f; // Example color
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  width: 200px; // Fixed width for alignment
`;

const CountdownContainer = styled.div`
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   justify-content: center;
//   background-color: #0D47A1; // Darker blue
//   padding: 20px;
//   border-radius: 5px;
//   flex: 1;
//   font-size: 28px;
// 	font-weight: 600;
// 	margin-bottom: 143px;
//     margin-top:160px;

display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #0D47A1; // Darker blue
  padding: 20px;
  border-radius: 5px;
  flex: 1;
  font-size: 21px;
  font-weight: 600;
  margin-top:51px;
  height: 379px;
  background-image:${countdownBackgroundImage};
  background-size:cover;
  background-position:center;
  box-shadow:inset 0 0 10px rgba(0,0,0,0.5);
`;

// Renderer for countdown
const CountdownRenderer = ({ days, hours, minutes, seconds, completed }) => {
  if (completed) {
    // Render a completed state
    return <span>Event started!</span>;
  } else {
    // Render a countdown
    return <span>{days} Days {hours} Hours {minutes} Minutes {seconds} Seconds</span>;
  }
};

function Practice() {
  const endDate = new Date(Date.now() + 357 * 24 * 60 * 60 * 1000); // Example end date

  return (
    <Container>
      <Content>
        <Headline>The fastest way to follow markets.</Headline>
        <Subtext>We offer users a fully operational long-term rental platform. It plans to leverages blockchain technology to ensure seamless rental experience.</Subtext>
        <Button>Whitepaper</Button>
        <Button>Learn More</Button>
      </Content>
      <CountdownContainer>
        <Countdown
          date={endDate}
          renderer={CountdownRenderer}
        />
        <Button>Renew Stay</Button>
      </CountdownContainer>
    </Container>
  );
}

export default Practice;
