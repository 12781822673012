import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useAuth } from "./AuthProvider";

import Refresh from './refresh';


const ProtectedRoute = ({ element }) => {
  const { isLoggedIn, loading } = useAuth();
  const location = useLocation();

  if (loading) {
    return <div>Loading...</div>;
  }
  return isLoggedIn ? element : <Navigate to="/login" state={{ from: location }} replace />;
};

export default ProtectedRoute;
