import react, { useEffect, useState } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';

function Manage({ building, setBuildingList, apartment, setApartmentList }) {

    const [info, setInfo] = useState([]);
    const [isLoading, setLoading] = useState(true);
    const [admin, setAdmin] = useState({});
    const [error, setError] = useState(null);
    const [general, setGeneral] = useState(null);
    const { userId } = useParams();
    const [activeSection, setActiveSection] = useState('userProfile');
    const [selectedPayment, setSelectedPayment] = useState(null);
    const [formData, setFormData] = useState({
        BuildingName: building ? building.BuildingName : '',
        BuildingLocation: building ? building.BuildingLocation : '',
        BuildingTotalRooms: building ? building.BuildingTotalRooms : '',
        BuildingInteriorImage1: 'https://picsum.photos/',
        BuildingInteriorImage2: 'https://picsum.photos/',
        BuildingInteriorImage3: 'https://picsum.photos/',
        BuildingExteriorImage4: 'https://picsum.photos/',
        BuildingExteriorImage5: 'https://picsum.photos/',
        BuildingExteriorImage6: 'https://picsum.photos/',
        BuildingManager: building ? building.BuildingManager : '',
        BuildingCity: building ? building.BuildingCity : '',
        BuildingDescript: building ? building.BuildingDescript : '',
    });
    const [formData2, setFormData2] = useState({
        BuildingId: apartment ? apartment.BuildingId : '',
        ApartmentNumber: apartment ? apartment.ApartmentNumber : '',
        ApartmentPrice: apartment ? apartment.ApartmentPrice : '',
        ApartmentType: apartment ? apartment.ApartmentType : '',
        ApartmentPreferedGender: apartment ? apartment.ApartmentPreferedGender : '',
        ApartmentDeposit: apartment ? apartment.ApartmentDeposit : '',
        ApartmentRating: apartment ? apartment.ApartmentRating : '',
        LivingArrangement: apartment ? apartment.LivingArrangement : '',
        BedRoomStatus: apartment ? apartment.BedRoomStatus : '',
        BedRoomCount: apartment ? apartment.BedRoomCount : '',
        Date: apartment ? apartment.Date : '',
        BuildingManager: apartment ? apartment.BuildingManager : '',
        Guest: apartment ? apartment.Guest : '',
        ApartmentLoungeImage: apartment ? apartment.ApartmentLoungeImage : '',
        ApartmentKitchenImage: apartment ? apartment.ApartmentKitchenImage : '',
        ApartmentBathroomImage: apartment ? apartment.ApartmentBathroomImage : '',
        ApartmentRoomImage: apartment ? apartment.ApartmentRoomImage : '',
        ApartmentSize: apartment ? apartment.ApartmentSize : '',
        LivingSpace: apartment ? apartment.LivingSpace : "",
        TotalNumOfBeds: apartment ? apartment.TotalNumOfBeds : ""
    });
    const [apartments, setApartments] = useState([]);

    const [buildings, setBuildings] = useState([]);

    const [get, setGet] = useState(null);

    const [get2, setGet2] = useState(null);

    const [uBuilding, setUBuilding] = useState({
        BuildingName: "",
        BuildingLocation: "",
        BuildingTotalRooms: "",
        BuildingInteriorImage1: "",
        BuildingInteriorImage2: "",
        BuildingInteriorImage3: "",
        BuildingExteriorImage4: "",
        BuildingExteriorImage5: "",
        BuildingExteriorImage6: "",
        BuildingManager: "",
        BuildingCity: "",
        BuildingDescript: "",
    });

    const [uApartment, setUApartment] = useState({
        BuildingId: '',
        ApartmentNumber: '',
        ApartmentType: '',
        ApartmentPreferedGender: '',
        ApartmentDeposit: '',
        ApartmentPrice: '',
        ApartmentRating: '',
        LivingArrangement: '',
        BedRoomStatus: '',
        BedRoomCount: '',
        Date: '',
        BuildingManager: '',
        Guest: '',
        ApartmentLoungeImage: '',
        ApartmentKitchenImage: '',
        ApartmentBathroomImage: '',
        ApartmentRoomImage: '',
        ApartmentSize: '',
        LivingSpace: "",
        TotalNumOfBeds: ""


    });

    const navigation = useNavigate();

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleChange2 = (e) => {
        setFormData2({ ...formData2, [e.target.name]: e.target.value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const method = building ? 'PUT' : 'POST';
        const url = building ? `https://backendjaes.passiontrials.co.za/building_tb/${building.BuildingId}` : 'https://backendjaes.passiontrials.co.za/building_tb';

        fetch(url, {
            method: method,
            headers: { 'Content-Type': 'application/json', },
            body: JSON.stringify(formData),
        })
            .then(res => res.json())
            .then(() => {
                alert('Building saved!');
                navigation('/');
            })
            .catch(error => {
                console.error('Error:', error);
                alert(`Failed to save building: ${error.message}`);
            });
    };

    const handleSubmit2 = (e) => {
        e.preventDefault();
        const method = apartment ? 'PUT' : 'POST';
        const url = apartment ? `https://backendjaes.passiontrials.co.za/apartment_tb/${apartment.ApartmentId}` : 'https://backendjaes.passiontrials.co.za/apartment_tb';
        console.log("submit two", apartment);
        fetch(url, {
            method: method,
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(formData2),
        })
            .then(res => res.json())
            .then(() => {
                // setApartmentList(prev => [prev, formData2]);
                alert('Apartment Saved!');
                setFormData2({
                    BuildingId: '',
                    ApartmentNumber: '',
                    ApartmentPrice: '',
                    ApartmentType: '',
                    ApartmentPreferedGender: '',
                    ApartmentDeposit: '',
                    ApartmentRating: '',
                    LivingArrangement: '',
                    BedRoomStatus: '',
                    BedRoomCount: '',
                    Date: '',
                    BuildingManager: '',
                    Guest: '',
                    ApartmentLoungeImage: '',
                    ApartmentKitchenImage: '',
                    ApartmentBathroomImage: '',
                    ApartmentRoomImage: '',
                    ApartmentSize: '',
                    LivingSpace: '',
                    TotalNumOfBeds: ''
                });

            })
            .catch(error => console.error('error : ', error));
    };

    const handleChange3 = (event) => {
        const { name, value } = event.target;
        setUBuilding((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };


    const handleChange4 = (event) => {
        const { name, value } = event.target;
        setUApartment((prevState) => ({
            ...prevState, [name]: value,
        }));
    };

    const handleChange5 = (event) => {
        const { name, value } = event.target;
        setAdmin((prevState) => ({
            ...prevState, [name]: value,
        }))
    };

    const handleSubmit3 = (event) => {
        event.preventDefault();
        fetch(`https://backendjaes.passiontrials.co.za/building_tb/${get}`, {
            method: "PUT", headers: { 'Content-Type': 'application/json', },
            body: JSON.stringify(uBuilding),
        })
            .then(res => {
                if (!res.ok) {
                    throw new Error(`HTTP status ${res.status}`);
                }
                return res.json();
            })
            .then(() => {
                alert('Building updated successfully');
                navigation('/');
            })
            .catch((error) => {
                console.error('Failed to update building:', error);
                alert(`Failed to update building: ${error.message}`);
            });

    };

    const handleSubmit4 = (event) => {
        event.preventDefault();
        fetch(`https://backendjaes.passiontrials.co.za/apartment_tb/${get2}`, {
            method: 'PUT', headers: { 'Content-Type': 'application/json', },
            body: JSON.stringify(uApartment),
        })
            .then(res => {
                if (!res.ok) {
                    throw new Error(`HTTP status ${res.status}`);
                }
                return res.json();
            })
            .then(() => {
                alert('Apartment updated successfully');
                //navigation('/');
                setActiveSection('Apartments');
            })
            .catch((error) => {
                console.error('Failed to update apartment: ', error);
                alert(`Failed to update apartment: ${error.message}`);
            });
    };

    const handleSubmit5 = (event) => {
        event.preventDefault();
        fetch(`https://backendjaes.passiontrials.co.za/admin_tb`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(admin),
        })
            .then(res => res.json())
            .then(() => {
                alert('Admin has been created');

            })
            .catch(error => console.error('error: ', error));
    };

    // useEffect(() => {

    //     // Uselect(id);
    // }, [id]);

    useEffect(() => {
        fetch(`https://backendjaes.passiontrials.co.za/apartment_tb`)
            .then(res => res.json())
            .then(data => setApartments(data))
            .catch(error => console.error('error :', error));


        fetch('https://backendjaes.passiontrials.co.za/new_building_tb')
            .then(res => res.json())
            .then(data => setBuildings(data))
            .catch(error => { console.error('error:', error) });


        fetch('https://backendjaes.passiontrials.co.za/admin_tb')
            .then(res => res.json())
            .then(data => setInfo(data))
            .catch(err => { console.log('An error occured:', err) });
    }, []);

    const Uselect = (id) => {
        fetch(`https://backendjaes.passiontrials.co.za/building_tb/single/${id}`)
            .then((response) => response.json())
            .then((data) => {
                console.log("Fetched data:", data[0]);
                setUBuilding((buildin) => ({ ...buildin, ...data[0] }));
                // console.log(building);
                setLoading(false);
            })
            .catch((error) => {
                console.error("Failed to load building data:", error);
                setLoading(false);
            });
    }

    const Uselect2 = (id) => {
        fetch(`https://backendjaes.passiontrials.co.za/apartment_tb/single/${id}`)
            .then((res) => res.json())
            .then((data) => {
                console.log("fetched apartment data:", data.data[0]);
                const apartmentData = data.data[0];
                setUApartment((u) => ({ ...u, ...apartmentData }));
                setLoading(false);

            })
            .catch((error) => {
                console.log('Failed to load apartment data: ', error);
                setLoading(false);
            });
    }

    function handleDelete(apartmentId) {
        fetch(`https://backendjaes.passiontrials.co.za/apartment_tb/${apartmentId}`, {
            method: 'DELETE',
        })
            .then(res => res.json())
            .then(() => {
                setApartments(prev => prev.filter(apartment => apartment.ApartmentId !== apartmentId));
                alert('Apartment deleted!');
            })
            .catch(error => console.error('error:', error));
    }

    const handleTabsClicked = (tabs) => {
        setSelectedPayment(tabs);
    };

    const generalInfo = (id) => {


    };

    const updateSection = (id) => {
        Uselect(id);
        setGet(id);
        console.log(`this is the passed value:${id}`);

        setActiveSection('updateBuilding');
    };

    const updateApartment = (id, id2) => {
        Uselect2(id);
        setGet2(id, id2);
        console.log('This is the apartment id:', get2, `these are the parameters ${id}:${id2} `);
        setActiveSection('updateApartment');

    };

    const backToManage = () => {
        setActiveSection('Buildings');
    }

    const backToManage2 = () => {
        setActiveSection('Apartments');
    }

    if (error) return <p>There is an error:{error}</p>;
    // if (info.length === 0) return <p>This user has no account records</p>;

    return (
        <div style={{ display: 'flex' }}>
            <div style={{ width: '20%', padding: '10px', borderRight: '1px solid #ddd' }}>
                <h2>Management</h2>
                <ul>
                    <li><Link to="#" onClick={() => setActiveSection('userProfile')}>Sub Admins</Link></li>
                    <li><Link to="#" onClick={() => setActiveSection('paymentHistory')}>Create an Admin</Link></li>
                    <li><Link to="#" onClick={() => setActiveSection('AddBuilding')}>Add Builidng</Link></li>
                    <li><Link to="#" onClick={() => setActiveSection('AddApartment')}>Add Apartment</Link></li>
                    <li><Link to='#' onClick={() => setActiveSection('Apartments')}>Apartments</Link></li>
                    {/* <li><Link to='#' onClick={() => setActiveSection('updateBuilding')}>Update Building</Link></li> not necessary to include */}
                    {/* <li><Link to='#' onClick={() => setActiveSection('updateApartment')}>Update Apartment</Link></li> */}
                    <li><Link to='#' onClick={() => setActiveSection('Buildings')}>Buildings</Link></li>






                </ul>

            </div>
            <div style={{ width: '80%', padding: '10px' }} >
                {activeSection === 'userProfile' && (
                    <div>
                        <h2>Create a new admin</h2>
                        <form onSubmit={handleSubmit5}>
                            <select

                                name="BuildingId"
                                value={admin.BuildingId}
                                onChange={handleChange5}
                            >
                                <option value=''>Select Building</option>
                                {buildings.map((building) => (
                                    <option key={building.BuildingId} value={building.buildingId}>
                                        {building.BuildingName}
                                    </option>
                                ))}
                            </select>
                            <br></br>
                            <input
                                type='text'
                                name='AdminUsername'
                                value={admin.AdminUsername}
                                onChange={handleChange5}
                                placeholder='username'
                            />
                            <br></br>
                            <input
                                type="text"
                                name="AdminName"
                                value={admin.AdminName}
                                onChange={handleChange5}
                                placeholder="Admin name"
                            />
                            <br></br>
                            <input
                                type='text'
                                name='AdminSurname'
                                value={admin.AdminSurname}
                                onChange={handleChange5}
                                placeholder='Admin surname'
                            />

                            <br></br>

                            <select
                                name="Role"
                                value={admin.Role}
                                onChange={handleChange5}
                            >
                                <option value=''>Select Role</option>
                                <option value='Admin'> Admin</option>
                                <option value='Super Admin'>Super Admin</option>
                            </select>
                            <br></br>
                            <input
                                type='email'
                                name='AdminEmail'
                                value={admin.AdminEmail}
                                onChange={handleChange5}
                                placeholder='Email'
                            />
                            <br></br>
                            <input
                                type='password'
                                name='AdminPassword'
                                value={admin.AdminPassword}
                                onChange={handleChange5}
                                placeholder='Password'
                            />
                            <br></br>
                            <button type='submit'>Create Admin</button>
                        </form>
                    </div>
                )}

                {activeSection === 'paymentHistory' && (
                    <div>
                        <h2>All sub admins</h2>
                        <div style={{ display: 'flex' }}>
                            <div style={{ width: '30%', paddingRight: '10px', borderRight: '1px soild #ddd' }}>
                                <ul>
                                    {info.map(payment => (
                                        <li key={payment.AdminId}>
                                            <button onClick={() => handleTabsClicked(payment)} style={{ background: 'none', border: 'none', textAlign: 'left', padding: '5px', cursor: 'pointer' }}>
                                                {payment.AdminEmail}
                                            </button>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                            <div style={{ width: '70%', paddingLeft: '10px' }}>
                                {selectedPayment && (
                                    <div>
                                        <p><strong>Created:</strong> {selectedPayment.AccountCreatedDate}</p>
                                        <p><strong>Admin Name:</strong> R{selectedPayment.AdminName}</p>
                                        <p><strong>Admin Surname:</strong> {selectedPayment.AdminSurname}</p>
                                        <p><strong>Last Logged in:</strong> {selectedPayment.AdminLastLogin}</p>
                                        <p><strong>Email Address:</strong> {selectedPayment.AdminEmail}</p>
                                        <p><strong>Role:</strong> <span className={`status ${selectedPayment.Role.toLowerCase()}`}>{selectedPayment.Role}</span></p>
                                    </div>
                                )}
                            </div>
                        </div>

                    </div>
                )}
                {activeSection === 'AddBuilding' && (

                    <div>
                        <h1>Add a new building</h1>
                        <form onSubmit={handleSubmit}>
                            <input
                                type="text"
                                name="BuildingName"
                                value={formData.BuildingName}
                                onChange={handleChange}
                                placeholder="Building Name"
                            />
                            <input
                                type="text"
                                name="BuildingLocation"
                                value={formData.BuildingLocation}
                                onChange={handleChange}
                                placeholder="Building Location"
                            />
                            <input
                                type="text"
                                name="BuildingTotalRooms"
                                value={formData.BuildingTotalRooms}
                                onChange={handleChange}
                                placeholder="Total Rooms"
                            />
                            <input
                                type="text"
                                name="BuildingManager"
                                value={formData.BuildingManager}
                                onChange={handleChange}
                                placeholder="Building Manager"
                            />
                            <input
                                type="text"
                                name="BuildingCity"
                                value={formData.BuildingCity}
                                onChange={handleChange}
                                placeholder="Building City"
                            />
                            <input
                                type="text"
                                name="BuildingDescript"
                                value={formData.BuildingDescript}
                                onChange={handleChange}
                                placeholder="Building Description"
                            />
                            <button type="submit">Save Building</button>
                        </form>
                    </div>
                )}
                {activeSection === 'AddApartment' && (
                    <div>
                        <h1>Add a new apartment</h1>
                        <form onSubmit={handleSubmit2}>
                            <select

                                name="BuildingId"
                                value={formData2.BuildingId}
                                onChange={handleChange2}
                            >
                                <option value=''>Select Building</option>
                                {buildings.map((building) => (
                                    <option key={building.BuildingId} value={building.BuildingId}>
                                        {building.BuildingName}
                                    </option>
                                ))}
                            </select>

                            <input
                                type="text"
                                name="ApartmentNumber"
                                value={formData2.ApartmentNumber}
                                onChange={handleChange2}
                                placeholder="Apartment Number"
                            />
                            <input
                                type="number"
                                name="ApartmentPrice"
                                value={formData2.ApartmentPrice}
                                onChange={handleChange2}
                                placeholder="Price"
                            />
                            <input
                                type="text"
                                name="ApartmentType"
                                value={formData2.ApartmentType}
                                onChange={handleChange2}
                                placeholder="Type"
                            />
                            <input
                                type="text"
                                name="ApartmentPreferedGender"
                                value={formData2.ApartmentPreferedGender}
                                onChange={handleChange2}
                                placeholder="Gender"
                            />
                            <input
                                type="number"
                                name="ApartmentDeposit"
                                value={formData2.ApartmentDeposit}
                                onChange={handleChange2}
                                placeholder="Deposit"
                            />
                            <input
                                type="number"
                                name="ApartmentRating"
                                value={formData2.ApartmentRating}
                                onChange={handleChange2}
                                placeholder="Rating"
                            />
                            <input
                                type="text"
                                name="LivingArrangement"
                                value={formData2.LivingArrangement}
                                onChange={handleChange2}
                                placeholder="Living Arrangement"
                            />
                            <input
                                type="text"
                                name="BedRoomStatus"
                                value={formData2.BedRoomStatus}
                                onChange={handleChange2}
                                placeholder="Bed room status"
                            />
                            <input
                                type="number"
                                name="BedRoomCount"
                                value={formData2.BedRoomCount}
                                onChange={handleChange2}
                                placeholder="Bedroom Count"
                            />
                            <input
                                type="text"
                                name="Date"
                                value={formData2.Date}
                                onChange={handleChange2}
                                placeholder="Date"
                            />
                            <input
                                type="text"
                                name="BuildingManager"
                                value={formData2.BuildingManager}
                                onChange={handleChange2}
                                placeholder="Building Manager"
                            />
                            <input
                                type="text"
                                name="Guest"
                                value={formData2.Guest}
                                onChange={handleChange2}
                                placeholder="Guest"
                            />
                            <input
                                type="text"
                                name="ApartmentSize"
                                value={formData2.ApartmentSize}
                                onChange={handleChange2}
                                placeholder="Size"
                            />
                            <input
                                type="text"
                                name="LivingSpace"
                                value={formData2.LivingSpace}
                                onChange={handleChange2}
                                placeholder="Living space"
                            />
                            <input
                                type="text"
                                name="TotalNumOfBeds"
                                value={formData2.TotalNumOfBeds}
                                onChange={handleChange2}
                                placeholder="Number of beds"
                            />
                            <button type="submit">Save Apartment</button>
                        </form>
                    </div>
                )}
                {activeSection === 'Apartments' && (
                    <div>
                        <h1>Apartments</h1>
                        {apartments.map(apartment => (
                            <div key={apartment.ApartmentId}>
                                <h3>{apartment.ApartmentNumber}</h3>
                                <p>{`Building ID: ${apartment.BuildingId}, Price: ${apartment.ApartmentPrice}`}</p>
                                <button onClick={() => updateApartment(apartment.ApartmentId, apartment.BuildingId)}>Edit</button>
                                <button>Delete</button>
                            </div>
                        ))}
                    </div>
                )}
                {activeSection === 'Buildings' && (

                    <div>
                        <h1>Buildings</h1>
                        {buildings.map(build => (
                            <div key={build.BuildingId}>
                                <h3>{build.BuildingName}</h3>
                                <p>{`Building ID: ${build.BuildingId},City: ${build.BuildingCity}`}</p>
                                <button onClick={() => updateSection(build.BuildingId)}>Edit</button>
                                <button>Delete</button>
                            </div>
                        ))}

                    </div>
                )}

                {activeSection === 'updateBuilding' && (

                    <div>
                        <h1>Update this building</h1>
                        <form onSubmit={handleSubmit3}>
                            {Object.entries(uBuilding).map(
                                ([key, value]) =>
                                    key !== 'BuildingId' && (
                                        <label key={key}>
                                            {key
                                                .replace(/([A-Z])/g, '$1')
                                                .replace(/^./, (str) => str.toUpperCase())

                                            }
                                            :

                                            <input type='text' name={key} value={value || ''} onChange={handleChange3} />
                                            <br></br>
                                        </label>

                                    )
                            )}
                            <button type='submit'>Update Building</button>
                            <br></br>
                            <button onClick={backToManage}>Return back</button>
                        </form>
                    </div>
                )}
                {activeSection === 'updateApartment' && (
                    <div>
                        <h2>Update this  Apartment</h2>
                        <form onSubmit={handleSubmit4}>
                            {Object.entries(uApartment).map(
                                ([key, value]) =>
                                    key !== "ApartmentId" && (
                                        <label key={key}>
                                            {key
                                                .replace(/([A-Z])/g, " $1")
                                                .replace(/^./, (str) => str.toUpperCase())}
                                            :
                                            <input
                                                type="text"
                                                name={key}
                                                value={value || ""}
                                                onChange={handleChange4}
                                            />
                                            <br></br>
                                        </label>
                                    )
                            )}
                            <button type="submit">Update Apartment</button>
                            <br></br>
                            <button onClick={backToManage2}>Return back</button>
                        </form>
                    </div>
                )}
            </div>
        </div >
    )


}

export default Manage;