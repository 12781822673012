import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';

function SingleTransaction() {
    const { id } = useParams();
    const [trans, setTrans] = useState(null);
    const [error, setError] = useState(null);
    const navigate = useNavigate();
    const location = useLocation();
    const { invoice } = location.state || null;

    // useEffect(() => {
    //     fetch(`https://backendjaes.passiontrials.co.za/user_single_invoice/${id}`, {
    //         headers: { 'Content-Type': 'application/json' }
    //     }).then(res => res.json())
    //         .then(data => { console.log(data[0]); setTrans(data[0]); })
    //         .catch(err => setError(err.message));
    // }, [id]);

    useEffect(() => {
        if (!invoice) {
            console.log('theres missing infomation:', invoice);
        }
        setTrans(invoice);
    }, [invoice])


    if (error) {
        return <p>There is an error:{error}</p>;

    }

    if (!trans) return <p style={{ marginRight: 'auto', marginLeft: 'auto' }}>Loading....</p>;


    return (
        <div>
            <h2>transaction #{trans.PayId}</h2>
            <p>Client: <span style={{ color: 'green' }}> {trans.Name} {trans.Surname}</span></p>
            <p>Amount: <span style={{ color: 'green' }}>R{trans.PayAmount} </span></p>
            <p>Due Date:  <span style={{ color: 'green' }}>{trans.PayNextDate}</span></p>
            <p>Status:  <span style={{ color: 'green' }}>{trans.PaymentStatus}</span></p>
            <p> Payment Method: <span style={{ color: 'green' }}>{trans.PayMethod}</span></p>
            <p> Advance Pay date: <span style={{ color: 'green' }}>{trans.PaidInAdvanceDate}</span></p>
        </div>
    )
}

export default SingleTransaction;