import React from 'react';
// import './App.css'; // Include your CSS
// import './designStuff/fonts/icomoon/style.css';
// import './designStuff/fonts/flaticon/font/flaticon.css';
// import './designStuff/css/tiny-slider.css';
// import './designStuff/css/aos.css';
// import './designStuff/css/style.css';
// import im1 from './designStuff/images/hero_bg_3.jpg';
// import im2 from './designStuff/images/hero_bg_2.jpg';
// import im3 from './designStuff/images/hero_bg_1.jpg';


const Design = () => {
    return (
        // <div className="App">
        //     {/* Example Navbar */}
        //     <nav className="site-nav">
        //         <div className="container">
        //             <div className="menu-bg-wrap">
        //                 <div className="site-navigation">
        //                     <a href="/" className="logo m-0 float-start">Property</a>
        //                     <ul className="js-clone-nav d-none d-lg-inline-block text-start site-menu float-end">
        //                         <li className="active"><a href="/">Home</a></li>
        //                         <li className="has-children">
        //                             <a href="/properties">Properties</a>
        //                             <ul className="dropdown">
        //                                 <li><a href="/">Buy Property</a></li>
        //                                 <li><a href="/">Sell Property</a></li>
        //                                 <li className="has-children">
        //                                     <a href="/">Dropdown</a>
        //                                     <ul className="dropdown">
        //                                         <li><a href="/">Sub Menu One</a></li>
        //                                         <li><a href="/">Sub Menu Two</a></li>
        //                                         <li><a href="/">Sub Menu Three</a></li>
        //                                     </ul>
        //                                 </li>
        //                             </ul>
        //                         </li>
        //                         <li><a href="/services">Services</a></li>
        //                         <li><a href="/about">About</a></li>
        //                         <li><a href="/contact">Contact Us</a></li>
        //                     </ul>
        //                     <a href="/" className="burger light me-auto float-end mt-1 site-menu-toggle js-menu-toggle d-inline-block d-lg-none">
        //                         <span></span>
        //                     </a>
        //                 </div>
        //             </div>
        //         </div>
        //     </nav>

        //     {/* Hero Section */}
        //     <div className="hero">
        //         <div className="hero-slide">
        //             <div className="img overlay" style={{ backgroundImage: `url(${im1})` }}></div>
        //             <div className="img overlay" style={{ backgroundImage: `url(${im1})` }}></div>
        //             <div className="img overlay" style={{ backgroundImage: `url(${im1})` }}></div>
        //         </div>

        //         <div className="container">
        //             <div className="row justify-content-center align-items-center">
        //                 <div className="col-lg-9 text-center">
        //                     <h1 className="heading">Easiest way to find your dream home</h1>
        //                     <form action="#" className="narrow-w form-search d-flex align-items-stretch mb-3">
        //                         <input type="text" className="form-control px-4" placeholder="Your ZIP code or City. e.g. New York" />
        //                         <button type="submit" className="btn btn-primary">Search</button>
        //                     </form>
        //                 </div>
        //             </div>
        //         </div>
        //     </div>

        //     {/* Example Section */}
        //     <div className="section">
        //         <div className="container">
        //             <div className="row mb-5 align-items-center">
        //                 <div className="col-lg-6">
        //                     <h2 className="font-weight-bold text-primary heading">Popular Properties</h2>
        //                 </div>
        //                 <div className="col-lg-6 text-lg-end">
        //                     <p><a href="#" className="btn btn-primary text-white py-3 px-4">View all properties</a></p>
        //                 </div>
        //             </div>
        //             <div className="row">
        //                 <div className="col-12">
        //                     <div className="property-slider-wrap">
        //                         <div className="property-slider">
        //                             <div className="property-item">
        //                                 <a href="/" className="img">
        //                                     <img src={im1} alt="Property" className="img-fluid" />
        //                                 </a>
        //                                 <div className="property-content">
        //                                     <div className="price mb-2"><span>$1,291,000</span></div>
        //                                     <div>
        //                                         <span className="d-block mb-2 text-black-50">5232 California Fake, Ave. 21BC</span>
        //                                         <span className="city d-block mb-3">California, USA</span>
        //                                         <div className="specs d-flex mb-4">
        //                                             <span className="d-block d-flex align-items-center me-3">
        //                                                 <span className="icon-bed me-2"></span>
        //                                                 <span className="caption">2 beds</span>
        //                                             </span>
        //                                             <span className="d-block d-flex align-items-center">
        //                                                 <span className="icon-bath me-2"></span>
        //                                                 <span className="caption">2 baths</span>
        //                                             </span>
        //                                         </div>
        //                                         <a href="/" className="btn btn-primary py-2 px-3">See details</a>
        //                                     </div>
        //                                 </div>
        //                             </div>
        //                             {/* Add more property items as needed */}
        //                         </div>
        //                         <div id="property-nav" className="controls">
        //                             <span className="prev" data-controls="prev">Prev</span>
        //                             <span className="next" data-controls="next">Next</span>
        //                         </div>
        //                     </div>
        //                 </div>
        //             </div>
        //         </div>
        //     </div>

        //     {/* Footer */}
        //     <div className="site-footer">
        //         <div className="container">
        //             <div className="row">
        //                 <div className="col-lg-4">
        //                     <div className="widget">
        //                         <h3>Contact</h3>
        //                         <address>43 Raymouth Rd. Baltemoer, London 3910</address>
        //                         <ul className="list-unstyled links">
        //                             <li><a href="tel://11234567890">+1(123)-456-7890</a></li>
        //                             <li><a href="tel://11234567890">+1(123)-456-7890</a></li>
        //                             <li><a href="mailto:info@mydomain.com">info@mydomain.com</a></li>
        //                         </ul>
        //                     </div>
        //                 </div>

        //                 <div className="col-lg-4">
        //                     <div className="widget">
        //                         <h3>Sources</h3>
        //                         <ul className="list-unstyled float-start links">
        //                             <li><a href="#">About us</a></li>
        //                             <li><a href="#">Services</a></li>
        //                             <li><a href="#">Vision</a></li>
        //                             <li><a href="#">Mission</a></li>
        //                             <li><a href="#">Terms</a></li>
        //                             <li><a href="#">Privacy</a></li>
        //                         </ul>
        //                         <ul className="list-unstyled float-start links">
        //                             <li><a href="#">Partners</a></li>
        //                             <li><a href="#">Business</a></li>
        //                             <li><a href="#">Careers</a></li>
        //                             <li><a href="#">Blog</a></li>
        //                             <li><a href="#">FAQ</a></li>
        //                             <li><a href="#">Creative</a></li>
        //                         </ul>
        //                     </div>
        //                 </div>

        //                 <div className="col-lg-4">
        //                     <div className="widget">
        //                         <h3>Links</h3>
        //                         <ul className="list-unstyled links">
        //                             <li><a href="#">Our Vision</a></li>
        //                             <li><a href="#">About us</a></li>
        //                             <li><a href="#">Contact us</a></li>
        //                         </ul>
        //                         <ul className="list-unstyled social">
        //                             <li><a href="#"><span className="icon-instagram"></span></a></li>
        //                             <li><a href="#"><span className="icon-twitter"></span></a></li>
        //                             <li><a href="#"><span className="icon-facebook"></span></a></li>
        //                             <li><a href="#"><span className="icon-linkedin"></span></a></li>
        //                             <li><a href="#"><span className="icon-pinterest"></span></a></li>
        //                             <li><a href="#"><span className="icon-dribbble"></span></a></li>
        //                         </ul>
        //                     </div>
        //                 </div>
        //             </div>

        //             <div className="row mt-5">
        //                 <div className="col-12 text-center">
        //                     <p>
        //                         Copyright &copy;{new Date().getFullYear()}. All Rights Reserved.
        //                         &mdash; Designed with love by <a href="https://untree.co">Untree.co</a>
        //                     </p>
        //                 </div>
        //             </div>
        //         </div>
        //     </div>
        // </div>
        <div>Nothing to show</div>
    );
}




export default Design;
