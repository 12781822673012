import React, { createContext, useContext, useEffect, useState, useMemo } from 'react';

const AuthContext = createContext({

    isLoggedIn: false,
    userId: null,
    loading: true,
    role: null,
    login: () => { },
    logout: () => { }
});

export const AuthProvider = ({ children }) => {
    const [isLoggedIn, setLoggedIn] = useState(false);
    const [userId, setUserId] = useState(null);
    const [loading, setLoading] = useState(true);
    const [role, setRole] = useState(null);

    useEffect(() => {

        const fetchUser = async () => {
            const storedLoggedInStatus = localStorage.getItem('isLoggedIn');
            const storedUserId = localStorage.getItem('userId');
            const storedRole = localStorage.getItem('role');
            console.log('Retrieved userId:', storedUserId);
            console.log('this is the role:', storedRole);

            if (storedLoggedInStatus === 'true' && storedUserId) {
                setLoggedIn(true);
                setUserId(storedUserId);
                setRole(storedRole);
            }
            setLoading(false)
        };

        fetchUser();
    }, [])

    const login = (id, role) => {
        setLoggedIn(true);
        localStorage.setItem('isLoggedIn', true);
        localStorage.setItem('userId', id);
        localStorage.setItem('role', role);
        setUserId(id);
        setRole(role);
        setLoading(false);
        // Add any side effects or additional logic if needed
    };
    const logout = () => {
        setLoggedIn(false);
        setUserId(null);
        setRole(null);
        localStorage.removeItem('isLoggedIn');
        localStorage.removeItem('userId');
        localStorage.removeItem('role');
        // Add any side effects or additional logic if needed
    };


    const value = useMemo(() => ({
        isLoggedIn,
        userId,
        role,
        login,
        logout,
        loading
    }), [isLoggedIn, userId, loading, role]);

    return (
        <AuthContext.Provider value={value}>
            {children}
        </AuthContext.Provider >
    );
};

export const useAuth = () => useContext(AuthContext);