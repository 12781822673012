import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, useLocation, Link } from 'react-router-dom';

function Customers() {

    const [cust, setCust] = useState([]);
    const [error, setError] = useState(null);




    useEffect(() => {

        fetch('https://backendjaes.passiontrials.co.za/user_tb', {
            headers: { 'Content-Type': 'application/json' }
        }).then(res => res.json())
            .then(data => {
                if (data) {
                    setCust(data);
                }
                else {
                    console.log('No customers found');
                }
            })
            .catch(err => {
                console.log("there was an error while fetching users:", err);
                setError('there was an error with fetching users', err);

            });

    }, []);


    return (
        <div>
            <h2>All registered users</h2>
            <div>
                {cust.map(cus => (
                    <div key={cus.UserId}>
                        <h2>ID #{cus.IdNumber}</h2>
                        <p>Custmer: <span style={{ color: 'green' }}> {cus.Name} {cus.Surname}</span></p>
                        <p>User ID: <span style={{ color: 'green' }}>{cus.UserId} </span></p>
                        <p>Email:  <span style={{ color: 'green' }}>{cus.Email}</span></p>
                        <p> Contact No.: <span style={{ color: 'green' }}>{cus.PhoneNumber}</span></p>
                        <p> Gender: <span style={{ color: 'green' }}>{cus.Gender}</span></p>
                        <p><Link to={`/individualCustomer/${cus.UserId}`}>more...</Link></p>
                    </div>
                ))

                }
            </div>
        </div >

    );
}



export default Customers;