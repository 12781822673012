import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useAuth } from './AuthProvider'; // Ensure your AuthProvider is correctly set up

function MyApartment() {
    const { userId, apartmentId } = useParams();
    const [apartmentDetails, setApartmentDetails] = useState(null);
    const [loading, setLoading] = useState(true);
    const [currentRecord, setCurrentRecord] = useState(null);
    const [error, setError] = useState('');

    //console.log("Retrieved userId:", userId); // This should log the userId from the URL
    // console.log("Retrieved apartmentId:", apartmentId);
    useEffect(() => {
        if (!userId || !apartmentId) {
            setError('Invalid apartment or user ID');
            setLoading(false);
            return;
        }

        fetch(`https://backendjaes.passiontrials.co.za/user_apartments/${userId}/${apartmentId}`, {
            headers: {
                'Content-Type': 'application/json',
                // Include authorization headers if needed, e.g., a bearer token
            }
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Failed to fetch apartment details');
                }
                return response.json();
            })
            .then(data => {
                setApartmentDetails(data[0]);
                console.log("it should display: ", data);
                setLoading(false);
            })
            .catch(err => {
                console.error('Error:', err);
                setError(err.message);
                setLoading(false);
            });

        fetch(`https://backendjaes.passiontrials.co.za/latest_record/${userId}/${apartmentId}`, {
            headers: { 'Content-Type': 'application/json' }
        }).then(response => response.json())
            .then(re => {
                if (re) {
                    setCurrentRecord(re);
                    // console.log(re);
                }
            }).catch(error => {
                console.error('Error', error);
                setError('Failed to load latest record');
            });


    }, [userId, apartmentId]);

    if (loading) return <div>Loading...</div>;
    if (error) return <div>Error: {error}</div>;
    if (!apartmentDetails) return <div>No apartment details found.</div>;

    return (
        <div>
            <h1>Apartment Details</h1>
            <div className="apartment-detail" style={{
                backgroundColor: '#f4f4f4',
                padding: '20px',
                borderRadius: '8px',
                margin: '20px'
            }}>
                <h2>{apartmentDetails.BuildingName}</h2>
                <div className="apartment-info" style={{
                    margin: '10px 0',
                    fontSize: '16px;'
                }}>
                    <p><strong>Apartment Number:</strong> {apartmentDetails.ApartmentNumber}</p>
                    <p><strong>Type:</strong> {apartmentDetails.ApartmentType}</p>
                    <p><strong>Rent:</strong> R{apartmentDetails.RentAmount}</p>
                    <p><strong>Deposit:</strong> R{apartmentDetails.ApartmentDeposit}</p>
                    <p><strong>Lease Term:</strong> {apartmentDetails.LeaseTerm}</p>
                    <p><strong>Move-In Date:</strong> {apartmentDetails.MoveInDate}</p>
                    <p><strong>Move-Out Date:</strong> {currentRecord?.PayNextDate || apartmentDetails.MoveOutDate}</p>
                    <p><strong>Status:</strong> {apartmentDetails.IsActive ? 'Active' : 'Inactive'}</p>
                    <p><strong>Preferred Gender:</strong> {apartmentDetails.ApartmentPreferedGender}</p>
                    <p><strong>Living Arrangement:</strong> {apartmentDetails.LivingArrangement}</p>
                    <p><strong>Bedroom Status:</strong> {apartmentDetails.BedRoomStatus}</p>
                    <p><strong>Number of Bedrooms:</strong> {apartmentDetails.BedRoomCount}</p>
                    <p><strong>Apartment Size:</strong> {apartmentDetails.ApartmentSize}</p>
                    <p><strong>Total Number of Beds:</strong> {apartmentDetails.TotalNumOfBeds}</p>
                    <p><strong>Guests Allowed:</strong> {apartmentDetails.Guest}</p>
                    <p><strong>Rating:</strong> {apartmentDetails.ApartmentRating}</p>
                    <p><strong>Notes:</strong> {apartmentDetails.Notes}</p>
                    <p><strong>Building Location:</strong> {apartmentDetails.BuildingLocation}</p>
                    <p><strong>Building City:</strong> {apartmentDetails.BuildingCity}</p>
                    <p><strong>Building Description:</strong> {apartmentDetails.BuildingDescript}</p>
                </div>
                <div className="apartment-images" style={{
                    width: '100%',
                    maxWidth: '300px',
                    margin: '10px',
                    borderRadius: '8px',
                }}>
                    <h3>Images</h3>
                    <img src={apartmentDetails.ApartmentLoungeImage} alt="Lounge" />
                    <img src={apartmentDetails.ApartmentKitchenImage} alt="Kitchen" />
                    <img src={apartmentDetails.ApartmentBathroomImage} alt="Bathroom" />
                    <img src={apartmentDetails.ApartmentRoomImage} alt="Room" />
                </div>
            </div>
        </div >
    );
}

export default MyApartment;
