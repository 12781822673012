import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';


function AdminApartmentDetails() {

    const { id } = useParams();
    const [apart, setApart] = useState(null);
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        fetch(`https://backendjaes.passiontrials.co.za/apartment_tb/singlewithjoint/${id}`, {
            headers: { 'Content-Type': 'application/json' }
        }).then(res => res.json())
            .then(data => { console.log(data); setApart(data.data[0]); })
            .catch(err => setError(err.message));
    }, [id]);

    if (error) {
        return <p>There is an error:{error}</p>;

    }

    if (!apart) return <p>Loading....</p>;


    return (
        <div>

            <h2>Apartment #{apart.ApartmentId}</h2>
            <p>Apartment Type: <span style={{ color: 'green' }}> {apart.ApartmentId} </span></p>
            <p>Price of Apartment: <span style={{ color: 'green' }}>R{apart.ApartmentPrice} </span></p>
            <p>Size of Apartment:  <span style={{ color: 'green' }}>{apart.ApartmentSize}</span></p>
            <p>Apartment No.:  <span style={{ color: 'green' }}>{apart.ApartmentNumber}</span></p>
            <p> Preferred Gender: <span style={{ color: 'green' }}>{apart.ApartmentPreferedGender}</span></p>
            <p> Manager: <span style={{ color: 'green' }}>{apart.BuildingManager}</span></p>
        </div>
    )
}

export default AdminApartmentDetails;