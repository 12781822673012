import react, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';


function IndividualCustomer() {
    const [info, setInfo] = useState([]);
    const [error, setError] = useState(null);
    const [general, setGeneral] = useState(null);
    const { UserId } = useParams();
    const [PayHistory, setPayHistory] = useState([]);
    const [activeSection, setActiveSection] = useState('userProfile');
    const [selectedPayment, setSelectedPayment] = useState(null);


    useEffect(() => {

        if (UserId) {
            userInfo(UserId);


        }


    }, [UserId]);


    const userInfo = (id) => {

        fetch(`https://backendjaes.passiontrials.co.za/all_monthly_user_payments/${UserId}`, {
            headers: { 'Content-Type': 'application/json' }
        }).then(res => res.json())
            .then(inn => {
                if (inn) {

                    setInfo(inn);
                    console.log('this is the info', inn);
                    setGeneral(inn[0]);
                    setSelectedPayment(inn[0]);

                }
                else {
                    console.log('there was no record found');
                }
            })
            .catch(error => {
                console.log('An error occurred while fetching data:', error);
            });
    };

    const handlePaymentClick = (payment) => {
        setSelectedPayment(payment);
    };

    if (error) return <p>There is an error:{error}</p>;
    if (info.length === 0) return <p>This user has no account records and has only registered</p>;

    return (
        <div style={{ display: 'flex' }}>
            <div style={{ width: '20%', padding: '10px', borderRight: '1px solid #ddd' }}>
                <h2>{general?.Name} {general?.Surname}'s account:#{general?.UserId}</h2>
                <ul>
                    <li><Link to="#" onClick={() => setActiveSection('userProfile')}>User Profile</Link></li>
                    <li><Link to="#" onClick={() => setActiveSection('paymentHistory')}>Payment History</Link></li>

                </ul>

            </div>
            <div style={{ width: '80%', padding: '10px' }} >
                {activeSection === 'userProfile' && (
                    <div>
                        <h2>Profile</h2>
                        <p>Name: {general?.Name}</p>
                        <p>Surname: {general?.Surname}</p>
                        <p>Email: {general?.Email}</p>
                        <p>Building Name: {general?.BuildingName}</p>
                        <p>Building City: {general?.BuildingCity}</p>
                    </div>
                )}

                {activeSection === 'paymentHistory' && (
                    <div>
                        <h2>Payment History</h2>
                        <div style={{ display: 'flex' }}>
                            <div style={{ width: '30%', paddingRight: '10px', borderRight: '1px soild #ddd' }}>
                                <ul>
                                    {info.map(payment => (
                                        <li key={payment.PayId}>
                                            <button onClick={() => handlePaymentClick(payment)} style={{ background: 'none', border: 'none', textAlign: 'left', padding: '5px', cursor: 'pointer' }}>
                                                {payment.PayDate}
                                            </button>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                            <div style={{ width: '70%', paddingLeft: '10px' }}>
                                {selectedPayment && (
                                    <div>
                                        <p><strong>Pay Date:</strong> {selectedPayment.PayDate}</p>
                                        <p><strong>Pay Amount:</strong> R{selectedPayment.PayAmount}</p>
                                        <p><strong>Pay Note:</strong> {selectedPayment.PayNote}</p>
                                        <p><strong>Payment Status:</strong> {selectedPayment.PaymentStatus}</p>
                                        <p><strong>Next Pay Date:</strong> {selectedPayment.PayNextDate}</p>
                                    </div>
                                )}
                            </div>
                        </div>

                    </div>
                )}
            </div>
        </div >
    )

}

export default IndividualCustomer;