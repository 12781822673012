import React, { useEffect, useState, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuth } from './AuthProvider';

function RecurringPayment() {
    const location = useLocation();
    const navigate = useNavigate();
    const { apartment, nextPayDate, latestInfo, amount } = location.state || {};
    //  const { latestInfo } = location.state || {};
    const checker = useRef(false);
    let amountConverted = parseFloat(amount);

    let recurringAmount = amountConverted;

    useEffect(() => {
        if (!apartment && !nextPayDate && !latestInfo && !amount) {
            console.warn("Missing information: ");
            return;
        }


        if (!checker.current) {
            console.log('Processing payment for:');
            recurringPayRecord();

            //createSubscriptionForm();
            createYocoPayment();
            checker.current = true;
        }

        //amount = latestInfo.PayAmount - latestInfo.ServiceFee;

        //  recurringAmount = latestInfo.PayAmount - latestInfo.ServiceFee;


    }, [apartment, nextPayDate, latestInfo]);


    if (latestInfo?.PaymentStatus.trim() == 'OVERDUE') {// needs to be fixed
        amount = amount + latestInfo?.PayAmount; // last months cost and this months cost
    }

    const createYocoPayment = async () => {
        try {
            const amountInCents = Math.round(parseFloat(recurringAmount) * 100);
            const response = await fetch('https://backendjaes.passiontrials.co.za/api/pay', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    amount: amountInCents,
                    currency: 'ZAR',
                    successUrl: `https://jaes.passiontrials.co.za/Payment?status=success&user=${apartment.UserId}`,
                    cancelUrl: 'https://jaes.passiontrials.co.za/cancel?status=cancel',
                })
            });

            if (!response.ok) {
                const errorText = await response.text();
                throw new Error(`Error: ${response.status} - ${errorText}`);
            }

            const data = await response.json();
            console.log('Payment response:', data);

            if (data.redirectUrl) {
                window.location.href = data.redirectUrl;
            }

        } catch (error) {
            console.error('Payment error', error);
        }
    };

    // const createSubscriptionForm = () => {
    //     const form = document.createElement('form');
    //     form.action = "https://sandbox.payfast.co.za/eng/process";
    //     form.method = "post";

    //     const inputs = [
    //         { name: "merchant_id", value: '10030988' },  //not included
    //         { name: "merchant_key", value: '2h99wu1jddrdr' }, //not included
    //         { name: 'amount', value: `${amount}` },
    //         { name: 'item_name', value: `${apartment.BuildingName} - ${apartment.ApartmentType}` }, // not included
    //         { name: 'return_url', value: `https://jaes.passiontrials.co.za/payment?recurringUser=${apartment.UserId}` }, //not included
    //         { name: 'cancel_url', value: `https://jaes.passiontrials.co.za/cancel` }, //not included
    //         { name: 'subscription_type', value: `1` },
    //         { name: 'billing_date', value: `2020-01-01` }, //better change this to the actual future date 
    //         { name: 'recurring_amount', value: `${recurringAmount}` },
    //         { name: 'frequency', value: `3` },
    //         { name: 'cycles', value: `0` },
    //         { name: 'subscription_notify_email', value: `true` },
    //         { name: 'subscription_notify_webhook', value: `true` },
    //         { name: 'subscription_notify_buyer', value: `true` },

    //     ];

    //     inputs.forEach(inputInfo => {
    //         const input = document.createElement("input");
    //         input.type = "hidden";
    //         input.name = inputInfo.name;
    //         input.value = inputInfo.value;
    //         form.appendChild(input);
    //     });


    //     document.body.appendChild(form);
    //     form.submit();

    // };

    const recurringPayRecord = async () => {
        try {


            const nextPayDate1 = nextPayDate; //comes from the userdash page

            const nextPayDay = new Date(nextPayDate1).toLocaleDateString('en-US', { day: 'numeric', month: "numeric", year: "numeric" });
            const currentPayday = new Date(nextPayDate1).toLocaleDateString('en-US', { day: 'numeric' });
            const converted = new Date(nextPayDate1);
            const dateNow = new Date();
            const daysOfStay = Math.ceil((converted - dateNow) / (1000 * 60 * 60 * 24));
            let upComingDate = '';
            const dateNowDay = new Date(dateNow).toLocaleDateString('en-US', { day: 'numeric' });

            let advanceDate = '';
            let paidInAdvance = false;
            let transactionDate = '';


            if (converted > dateNow) {  //check the current date days
                //this section is advance pay // its for calculating the days 

                //    nextPaymentDate.setDate(new Date(nextPaymentDate.getFullYear(), nextPaymentDate.getMonth() + 1, 0).getDate());


                advanceDate = dateNow.toLocaleDateString('en-US', { day: 'numeric', month: 'long', year: 'numeric' });
                paidInAdvance = true;
                //  converted.setMonth(converted.getMonth());
                // converted.setDate(new Date(converted.getFullYear(), converted.getMonth() + 1, 0).getDate());
                upComingDate = converted.toLocaleDateString('en-US', { day: 'numeric', month: "long", year: "numeric" });
                transactionDate = dateNow.toLocaleDateString('en-US', { day: 'numeric', month: 'long', year: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: true });
            }
            else if (converted === dateNow) {
                //last day of pay
                // console.log('this is the current day date', dateNowDay);
                advanceDate = `N/A`;

                // converted.setMonth(converted.getMonth() + 1);
                //converted.setDate(new Date(converted.getFullYear(), converted.getMonth() + 1, 0).getDate());
                upComingDate = converted.toLocaleDateString('en-US', { day: 'numeric', month: "long", year: "numeric" });
                transactionDate = dateNow.toLocaleDateString('en-US', { day: 'numeric', month: 'long', year: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: true });

            }
            else if (converted < dateNow) {
                //this is the next month scenario

            }
            // else { // for testing only
            //     advanceDate = `N/A`;

            //     converted.setMonth(converted.getMonth() + 1, 1);
            //     upComingDate = converted.toLocaleDateString('en-US', { day: 'numeric', month: "long", year: "numeric" });

            // }




            const payResponse = await fetch('https://backendjaes.passiontrials.co.za/payment_tb', {
                method: "POST",
                headers: { 'Content-Type': "application/json" },
                body: JSON.stringify({

                    UserId: apartment.UserId,
                    ApartmentId: apartment.ApartmentId,
                    PayDate: transactionDate,
                    PayNextDate: upComingDate,
                    PayAmount: amountConverted,
                    PayNote: "Monthly rent",
                    PaymentStatus: "Failed to pay",
                    PaymentType: "Rent",
                    PayAdvancePeriod: '15',
                    PayGracePeriod: '3',
                    PayMethod: "YOCO",
                    Paid: false,
                    PaidInAdvance: paidInAdvance,
                    PaidInAdvanceDate: advanceDate,
                    IsGracePeriod: false,
                    ChosenPlan: latestInfo?.ChosenPlan,
                    ServiceFee: latestInfo?.ServiceFee,
                    PayableRent: amountConverted,
                    RentCost: latestInfo?.RentCost

                })
            });







            if (!payResponse.ok) {
                const errorText = await payResponse.text();
                throw new Error(`Failed to save payment record: ${errorText}`);
            }
            console.log('recurring Payment recorded successfully!');



        } catch (error) {
            console.log("an error occurred while saving payment record");
            alert(`Error processing your payment ${error.message}`);
        }
    }
    if (!apartment && !nextPayDate && !latestInfo) {
        return <div style={{ marginTop: '149px' }}>Error: missing information ,please ensure all data is provided.</div>
    }

    return (
        <div style={{ marginTop: '149px' }}>
            <h1>Processing Payment...</h1>
            <p>If you are not automatically redirected, please click submit. </p>
        </div>
    )



}

export default RecurringPayment;