import React, { useState } from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { useAuth } from './AuthProvider';
import './assets/css/style-starter.css';
import bcrypt from 'bcryptjs';
import logo from './logoblack.png';
import './Login.css';

function Login() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [compare, setCompare] = useState(null);
    const [updatePassword, setUpdatePassword] = useState(false);
    const [error, setError] = useState('');
    const [step, setStep] = useState(1); // Step 1: Email input, Step 2: Password input

    const navigate = useNavigate();
    const location = useLocation();
    const { login } = useAuth();

    const handleEmailSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch(`https://backendjaes.passiontrials.co.za/getUser_with_email/${email}`, {
                headers: { 'Content-Type': 'application/json' },
            });

            const data = await response.json();
            if (data && data.length > 0) {
                setCompare(data[0]);
                if (data[0].Password === 'null' || data[0].Password === null) {
                    setUpdatePassword(true);
                } else {
                    setUpdatePassword(false);
                }
                setStep(2); // Move to step 2 after checking the password
            } else {
                alert('User not found');
            }
        } catch (error) {
            console.error('Error fetching user:', error);
            alert('An error occurred during login');
        }
    };

    const handlePasswordSubmit = async (e) => {
        e.preventDefault();
        if (updatePassword) {
            handleUpdatePassword();
        } else {
            normalLogin(email, password);
        }
    };

    const normalLogin = async (email, password) => {
        try {
            const loginResponse = await fetch('https://backendjaes.passiontrials.co.za/loginn', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ Email: email, Password: password }),
            });

            const loginData = await loginResponse.json();

            if (loginResponse.ok) {
                login(loginData.userId, loginData.Role);
                if (loginData.Role === 'Admin' || loginData.Role === 'Super Admin') {
                    navigate('/adminDashboard');
                } else if (loginData.Role === 'Transport') {
                    navigate('/transport-admin');
                } else {
                    checkApartmentAndNavigate(loginData.userId);
                }
            } else {
                alert(loginData.message || 'Failed to login');
            }
        } catch (error) {
            console.error('Login error:', error);
            alert('An error occurred during login');
        }
    };


    const handleUpdatePassword = async () => {
        if (newPassword !== confirmPassword) {
            alert('Passwords do not match.');
            return;
        }

        try {
            const hashedPassword = await bcrypt.hash(newPassword, 10);

            const response = await fetch(`https://backendjaes.passiontrials.co.za/updateUser_login/${compare.UserId}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    ...compare,
                    Password: hashedPassword,
                    ConfirmedUser: 1,
                }),
            });

            const data = await response.json();

            if (response.ok) {
                //alert('Password updated successfully. You will be signed in, in a moment...');
                setUpdatePassword(false);
                normalLogin(email, newPassword);
            } else {
                alert(data.message);
            }
        } catch (error) {
            console.error('Error updating password:', error);
        }
    };

    const checkApartmentAndNavigate = async (userId) => {
        try {
            const res = await fetch(`https://backendjaes.passiontrials.co.za/check_apartment_paid/${userId}`);
            const result = await res.json();

            if (res.ok && result.hasPaid) {
                navigate('/UserDashboard');
            } else {
                const from = location.state?.from?.pathname || '/';
                const state = location.state?.from?.state || {};
                navigate(from, { state });
            }
        } catch (error) {
            console.error('Error checking user apartment status:', error);
            const from = location.state?.form?.pathname || '/';
            const state = location.state?.from?.state || {};
            navigate(from, { state });
        }
    };

    return (
        <div className="login-page">
            <div className="login-container">
                <img src={logo} alt="Logo" className="logo" />

                <form onSubmit={step === 1 ? handleEmailSubmit : handlePasswordSubmit} className="login-form">
                    {step === 1 && (
                        <div className="input-grouplogin">
                            <input
                                type="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                placeholder="Email or Phone Number"
                                required
                                className="input-fieldlogin"
                            />
                            <button type="submit" className="input-button">
                                <i className="arrow-icon">Continue ➔</i>
                            </button>
                        </div>
                    )}

                    {step === 2 && !updatePassword && (
                        <div className="input-grouplogin">
                            <input
                                type="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                placeholder="Email"
                                required
                                className="input-fieldlogin"
                                readOnly
                            />
                            <input
                                type="password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                placeholder="Password"
                                required
                                className="input-fieldlogin"
                            />
                            <button type="submit" className="input-button">
                                <i className="arrow-icon">Login ➔</i>
                            </button>
                        </div>
                    )}

                    {step === 2 && updatePassword && (
                        <div className="input-grouplogin">
                            <input
                                type="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                placeholder="Email"
                                required
                                className="input-fieldlogin"
                                readOnly
                            />
                            <input
                                type="password"
                                value={newPassword}
                                onChange={(e) => setNewPassword(e.target.value)}
                                placeholder="New Password"
                                required
                                className="input-fieldlogin"
                            />
                            <input
                                type="password"
                                value={confirmPassword}
                                onChange={(e) => setConfirmPassword(e.target.value)}
                                placeholder="Confirm New Password"
                                required
                                className="input-fieldlogin"
                            />
                            <button type="submit" className="input-button">
                                <i className="arrow-icon">Update Password ➔</i>
                            </button>
                        </div>
                    )}
                </form>

                <div className="options">
                    <label>
                        <input type="checkbox" />
                        Keep me signed in
                    </label>
                    <div className="links">
                        <Link to="/ForgotPassword">Forgot password?</Link>
                        <Link to="/register">Sign Up</Link>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Login;
