import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";

function Edit_building() {
  const [building, setBuilding] = useState({
    BuildingName: "",
    BuildingLocation: "",
    BuildingTotalRooms: "",
    BuildingInteriorImage1: "",
    BuildingInteriorImage2: "",
    BuildingInteriorImage3: "",
    BuildingExteriorImage4: "",
    BuildingExteriorImage5: "",
    BuildingExteriorImage6: "",
    BuildingManager: "",
    BuildingCity: "",
    BuildingDescript: "",
  });
  const [isLoading, setLoading] = useState(true);
  const { buildingId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    fetch(
      `https://backendjaes.passiontrials.co.za/building_tb/single/${buildingId}`
    )
      .then((response) => response.json())
      .then((data) => {
        console.log("Fetched data:", data);
        setBuilding((building) => ({ ...building, ...data }));
        // console.log(building);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Failed to load building data:", error);
        setLoading(false);
      });
  }, [buildingId]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setBuilding((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    fetch(`https://backendjaes.passiontrials.co.za/building_tb/${buildingId}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(building),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP status ${response.status}`);
        }
        return response.json();
      })
      .then(() => {
        alert("Building updated successfully");
        navigate("/");
      })
      .catch((error) => {
        console.error("Failed to update building:", error);
        alert(`Failed to update building: ${error.message}`);
      });
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <h2>Edit Building</h2>
      <form onSubmit={handleSubmit}>
        {Object.entries(building).map(
          ([key, value]) =>
            key !== "BuildingId" && (
              <label key={key}>
                {key
                  .replace(/([A-Z])/g, " $1")
                  .replace(/^./, (str) => str.toUpperCase())}
                :
                <input
                  type="text"
                  name={key}
                  value={value || ""}
                  onChange={handleChange}
                />
              </label>
            )
        )}
        <button type="submit">Update Building</button>
      </form>
    </div>
  );
}

export default Edit_building;
