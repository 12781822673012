import { BrowserRouter, Routes, Route } from "react-router-dom";
import Add_building from "./Add_building";
import Home_page from "./Home_page";
import Login from "./login";
import Register from "./register";
import Apartments from "./Apartments";
import Edit_building from './Edit_building';
import ApartmentDetails from "./apartmentDetails";
import Invoice from './Invoice';
import Payment from './Payment';
import Cancel from "./cancel";
import UserDashboard from "./userDashboard";
import AdminDashboard from "./adminDashboard";
import { AuthProvider } from "./AuthProvider";
import ProtectedRoute from "./protectedRoute";
import PaymentProcess from './paymentProcess';
import Navbar from './navBar';
import MyApartment from "./myApartment";
import Profile from "./Profile";
import Practice from "./practice";
import RecurringPayment from "./recurringPayment";
import UserPayment from "./userPayment";
import UserInvoice from "./userInvoice";
import CheckOut from './checkOut';
import TransactionInvoice from './transactionInvoice';
import AdminApartments from "./adminApartments";
import AdminApartmentDetails from "./adminApartmentDetails";
import SingleTransaction from "./singleTransaction";
import Customers from "./customers";
import IndividualCustomer from "./individualCustomer";
import Manage from './manage';
import YOCO from './YOCO';
import Design from './design';
import YocoPayment from "./yocoPayment";
import Transport from './Transport';
import TransportAdmin from './TransportAdmin';
import ForgotPassword from "./ForgotPassword";
import ResetPassword from "./ResetPassword";
import Building from "./Building";
//const Texting = () => <div>this is a test</div>;

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <AuthProvider>
          <Navbar />
          <Routes>
            <Route path="/" element={<Home_page />} />
            <Route path="/Add_building" element={<ProtectedRoute element={<Add_building />} />} />
            <Route path="/Edit_building/:buildingId" element={<ProtectedRoute element={<Edit_building />} />} />
            <Route path="/Login" element={<Login />} />
            <Route path="/Register" element={<Register />} />
            <Route path="/Apartments/:buildingId" element={<Apartments />} />
            <Route path="/ApartmentDetails/:apartmentId" element={<ApartmentDetails />} />
            <Route path='/Invoice' element={<Invoice />} />
            <Route path='/UserInvoice/:id' element={<ProtectedRoute element={<UserInvoice />} />} />
            <Route path='/Payment' element={<Payment />} />
            <Route path='/Cancel' element={<Cancel />} />
            <Route path='/Practice' element={<Practice />} />
            <Route path='/Design' element={<Design />} />
            <Route path='/Building' element={<Building />} />

            <Route path='/AdminApartmentDetails/:id' element={<AdminApartmentDetails />} />
            <Route path='/UserPayment' element={<ProtectedRoute element={<UserPayment />} />} />
            <Route path='/TransactionInvoice' element={<TransactionInvoice />} />
            <Route path='/SingleTransaction/:id' element={<SingleTransaction />} />
            <Route path='/CheckOut' element={<ProtectedRoute element={<CheckOut />} />} />
            <Route path='/customers' element={<Customers />} />
            <Route path='/Manage' element={<Manage />} />
            <Route path='/YOCO' element={<YOCO />} />

            <Route path='/IndividualCustomer/:UserId' element={<IndividualCustomer />} />
            <Route path='/UserDashboard/*' element={<ProtectedRoute element={<UserDashboard />} />}>
              <Route path="MyApartment/:userId/:apartmentId" element={<MyApartment />} />

            </Route>
            <Route path="/ForgotPassword" element={<ForgotPassword />} />
            <Route path="/ResetPassword/:token" element={<ResetPassword />} />
            <Route path="Profile" element={<Profile />} />
            <Route path="/Transport" element={<Transport />} />
            <Route path="/transport-admin" element={<TransportAdmin />} /> {/* Add the admin page route */}
            <Route path="/AdminDashboard" element={<ProtectedRoute element={<AdminDashboard />} />} />
            <Route path="/AdminApartments" element={<ProtectedRoute element={<AdminApartments />} />} />

            <Route path="/PaymentProcess" element={<ProtectedRoute element={<PaymentProcess />} />} />
            <Route path='/YocoPayment' element={<ProtectedRoute element={<YocoPayment />} />} />
            <Route path='/RecurringPayment' element={<ProtectedRoute element={<RecurringPayment />} />} />
          </Routes>
        </AuthProvider>
      </BrowserRouter>
    </div>
  );
}

export default App;
