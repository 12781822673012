import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";

function Add_building({ building, setBuildingList }) {
    const [formData, setFormData] = useState({
        BuildingName: building ? building.BuildingName : '',
        BuildingLocation: building ? building.BuildingLocation : '',
        BuildingTotalRooms: building ? building.BuildingTotalRooms : '',
        BuildingInteriorImage1: 'https://picsum.photos/',
        BuildingInteriorImage2: 'https://picsum.photos/',
        BuildingInteriorImage3: 'https://picsum.photos/',
        BuildingExteriorImage4: 'https://picsum.photos/',
        BuildingExteriorImage5: 'https://picsum.photos/',
        BuildingExteriorImage6: 'https://picsum.photos/',
        BuildingManager: building ? building.BuildingManager : '',
        BuildingCity: building ? building.BuildingCity : '',
        BuildingDescript: building ? building.BuildingDescript : '',
    });
    const navigation = useNavigate();
    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const method = building ? 'PUT' : 'POST';
        const url = building ? `https://backendjaes.passiontrials.co.za/building_tb/${building.BuildingId}` : 'https://backendjaes.passiontrials.co.za/building_tb';

        fetch(url, {
            method: method,
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(formData),
        })
            .then(response => response.json())
            .then(() => {
                //  setBuildingList(prev => [...prev, formData]); // Update building list
                alert('Building saved!');
                navigation("/");
            })
            .catch(error => {
                console.error('Error:', error);
                alert(`Failed to save building: ${error.message}`);
            });
    };

    return (
        <form onSubmit={handleSubmit}>
            <input
                type="text"
                name="BuildingName"
                value={formData.BuildingName}
                onChange={handleChange}
                placeholder="Building Name"
            />
            <input
                type="text"
                name="BuildingLocation"
                value={formData.BuildingLocation}
                onChange={handleChange}
                placeholder="Building Location"
            />
            <input
                type="text"
                name="BuildingTotalRooms"
                value={formData.BuildingTotalRooms}
                onChange={handleChange}
                placeholder="Total Rooms"
            />
            <input
                type="text"
                name="BuildingManager"
                value={formData.BuildingManager}
                onChange={handleChange}
                placeholder="Building Manager"
            />
            <input
                type="text"
                name="BuildingCity"
                value={formData.BuildingCity}
                onChange={handleChange}
                placeholder="Building City"
            />
            <input
                type="text"
                name="BuildingDescript"
                value={formData.BuildingDescript}
                onChange={handleChange}
                placeholder="Building Description"
            />
            <button type="submit">Save Building</button>
        </form>

    );
}

export default Add_building;
