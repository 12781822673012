import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

function UserInvoice() {
    const { id } = useParams();
    const [invoice, setInvoice] = useState(null);
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        fetch(`https://backendjaes.passiontrials.co.za/user_single_invoice/${id}`, {
            headers: { 'Content-Type': 'application/json' }
        }).then(res => res.json())
            .then(data => { console.log(data); setInvoice(data[0]); })
            .catch(err => setError(err.message));
    }, [id]);

    if (error) {
        return <p>There is an error:{error}</p>;

    }

    if (!invoice) return <p>Loading....</p>;


    return (
        <div>
            <h2>Invoice #{invoice.PayId}</h2>
            <p>Client: <span style={{ color: 'green' }}> {invoice.clientName} Name Surname and Email</span></p>
            <p>Amount: <span style={{ color: 'green' }}>R{invoice.PayAmount} </span></p>
            <p>Due Date:  <span style={{ color: 'green' }}>{invoice.PayNextDate}</span></p>
            <p>Status:  <span style={{ color: 'green' }}>{invoice.PaymentStatus}</span></p>
            <p> Payment Method: <span style={{ color: 'green' }}>{invoice.PayMethod}</span></p>
            <p> Advance Pay date: <span style={{ color: 'green' }}>{invoice.PaidInAdvanceDate}</span></p>
        </div>
    )
}

export default UserInvoice;