import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import './slides.css';
import './card.css';

function chunkArray(array, chunkSize) {
    const result = [];
    for (let i = 0; i < array.length; i += chunkSize) {
        result.push(array.slice(i, i + chunkSize));
    }
    return result;
}

function Building() {
    const [buildings, setBuildings] = useState([]);
    const [zone, setZone] = useState(""); // Default to no zone filter
    const [gender, setGender] = useState(""); // Default to no gender filter
    const [price, setPrice] = useState(""); // Default to no price filter

    const buildingRows = chunkArray(buildings, 3);

    useEffect(() => {
        buildingInfo();
    }, []);

    const buildingInfo = () => {
        fetch(`https://backendjaes.passiontrials.co.za/new_building_tb`)
            .then(res => res.json())
            .then(info => {
                setBuildings(info);
                console.log('this is the data that must be revealed', info);
            })
            .catch(error => {
                console.log('An error occurred', error);
            });
    };

    const handleGender = (binary) => {
        if (binary) {
            if (binary == 0) {
                return 'Female';
            }

            if (binary == 1) {
                return 'Male';
            }

            if (binary == 2) {
                return 'Female | Male';
            }
        }
    };

    const handleFilterChange = () => {
        // Apply the filters based on zone, gender, and price
        fetch(`https://backendjaes.passiontrials.co.za/new_building_tb?zone=${zone}&gender=${gender}`)
            .then(res => res.json())
            .then(filteredBuildings => {
                setBuildings(filteredBuildings);
            })
            .catch(error => console.log('Error filtering buildings:', error));
    };

    return (
        <body>
            {/* <section class="slider_container">
        <section class="slider">
          <div class="slide one">
            <img src="https://jaes.co.za/wp-content/uploads/2024/01/IMG_20230226_113005-scaled.jpg" alt="" />
            <span class="caption"> slide uno </span>
          </div>
          <div class="slide two">
            <img src="https://jaes.co.za/wp-content/uploads/2024/01/IMG_20230226_113005-scaled.jpg" alt="" />
            <span class="caption"> slide two </span>
          </div>
          <div class="slide three">
            <img src="https://jaes.co.za/wp-content/uploads/2024/01/IMG_20230226_113005-scaled.jpg" alt="" />
            <span class="caption"> slide three </span>
          </div>
          <div class="slide four">
            <img src="https://jaes.co.za/wp-content/uploads/2024/01/IMG_20230226_113005-scaled.jpg" alt="" />
            <span class="caption"> slide four </span>
          </div>
          <div class="slide four">
            <img src="https://jaes.co.za/wp-content/uploads/2024/01/IMG_20230226_113005-scaled.jpg" alt="" />
            <span class="caption"> slide five </span>
          </div>
        </section>
      </section> */}
            <section class="w3l-form-main py-5" id="book">
                <div class="container py-lg-5 py-md-4 py-2 d-flex justify-content-center">
                    <div class="d-flex align-items-center justify-content-center">
                        {/* Zone Filter */}
                        <div class="me-3">
                            {/* <label for="zoneFilter" class="form-label">Zone:</label> */}
                            <select
                                id="zoneFilter"
                                class="form-control"
                                value={zone}
                                onChange={(e) => setZone(e.target.value)}
                                style={{ width: '200px' }}
                            >
                                <option value="">All Zones</option>
                                <option value="1">Zone 1</option>
                                <option value="2">Zone 2</option>
                                <option value="3">Zone 3</option>
                                <option value="4">Zone 4</option>
                            </select>
                        </div>

                        {/* Gender Filter */}
                        <div class="me-3">
                            {/* <label for="genderFilter" class="form-label">Gender:</label> */}
                            <select
                                id="genderFilter"
                                class="form-control"
                                value={gender}
                                onChange={(e) => setGender(e.target.value)}
                                style={{ width: '200px' }}
                            >
                                <option value="">All Genders</option>
                                <option value="0">Female</option>
                                <option value="1">Male</option>
                                <option value="2">Female | Male</option>
                            </select>
                        </div>

                        {/* Apply Filters Button */}
                        <div>
                            <button
                                class="btn btn-primary"
                                onClick={handleFilterChange}
                                style={{ width: '200px', height: '38px' }} // Match size of inputs
                            >
                                Apply Filters
                            </button>
                        </div>
                    </div>
                </div>


            </section>
            {/* <section class="w3l-aboutblock py-lg-5 py-4" id="about">
        <div class="container">
          <div class="row align-items-center">
            <div class="col-lg-6">
              <img src="https://jaes.co.za/wp-content/uploads/2024/01/IMG_2865-scaled.jpg" alt="" class="img-fluid radius-image" />
            </div>
            <div class="col-lg-6 ps-xl-5 ps-lg-4 mt-lg-0 mt-5">
              <div class="title-main">
                <p>About Us</p>
                <h3 class="title-style">Plan Your Trip with Tours</h3>
              </div>
              <p class="mt-3">Lorem ipsum viverra feugiat. Pellen tesque libero ut justo,
                ultrices in ligula. Semper at tempufddfel. Lorem ipsum dolor sit amet consectetur adipisicing
                elit.</p>
              <ul class="mt-4 list-style-lis">
                <li><i class="fas fa-check-circle"></i>2000+ Our Worldwide Guide</li>
                <li class="mt-2"><i class="fas fa-check-circle"></i>100% Trusted Tour Agency</li>
                <li class="mt-2"><i class="fas fa-check-circle"></i>24+ Years of Experience</li>
                <li class="mt-2"><i class="fas fa-check-circle"></i>100% Travelers are Happy</li>
              </ul>
              <a href="contact.html" class="btn btn-style mt-5">Booking Now</a>
            </div>
          </div>
        </div>
      </section> */}
            {/* Building Listings */}
            <section className="w3l-index5 pb-5 pt-4">
                <div className="container-fluid py-lg-5 py-md-4 py-2">
                    <div className="title-main text-center mx-auto mb-md-5 mb-4" style={{ maxWidth: '600px' }}>
                        <p>Featured Apartments</p>
                        <h3 className="title-style">Most Popular Apartments</h3>
                    </div>
                    <div className="room-card-container">
                        {buildingRows.map((row, rowIndex) => (
                            <div key={rowIndex}>
                                {row.map((build) => (
                                    <Link
                                        to={`/Apartments/${build.BuildingId}`} // Only passing buildingId in the route
                                        className="view-link"
                                        key={build.BuildingId}
                                    >
                                        <div className="room-card">
                                            <img src={`https://backendjaes.passiontrials.co.za/src${build.BuildingExteriorImage4}`} alt="Building Exterior" className="room-card-image" />
                                            <div className="room-card-content">
                                                <h2 className="room-title">{build.BuildingName}</h2>
                                                <p className="b-type"><i className="fas fa-map-marker-alt me-1"></i>{build.BuildingLocation}</p>
                                                <div className="plan-options">
                                                    <button className="plan-option"> {handleGender(build.PreferedGender)}</button>
                                                </div>
                                                <Link to={`/Apartments/${build.BuildingId}`} className="view-link">
                                                    <i className="fas fa-bed me-1"></i>{build.Setup}
                                                </Link>
                                                <div className="room-details">
                                                    <div className="detail-item">
                                                        <p>Building Capacity</p>
                                                        <span>{build.TotalCapacity}</span>
                                                    </div>
                                                    <div className="detail-item">
                                                        <p>Zone</p>
                                                        <span>{build.Zone}</span>
                                                    </div>
                                                    <div className="detail-item">
                                                        <p>Beds Available</p>
                                                        <span>{build.Capacity}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Link>
                                ))}
                            </div>
                        ))}

                    </div>
                </div>
            </section>


            <footer class="w3l-footer-29-main py-5">
                <div class="container pt-lg-5 pt-sm-4 pt-2 pb-2">
                    <div class="row footer-top-29">
                        <div class="col-lg-4 col-md-6 footer-list-29">
                            <h6 class="footer-title-29">Contact Info </h6>
                            <p class="mb-2 pe-xl-5">Address : Tours, 10001, 5th Avenue, #06 lane street, NY - 62617.
                            </p>
                            <p class="mb-2">Phone Number : <a href="tel:+1(21) 234 4567">+1(21) 234 4567</a></p>
                            <p class="mb-2">Email : <a href="mailto:info@example.com">info@example.com</a></p>
                            <div class="mt-4">
                                <ul class="social">
                                    <li><a href="#facebook"><i class="fab fa-facebook-f"></i></a>
                                    </li>
                                    <li><a href="#linkedin"><i class="fab fa-linkedin-in"></i></a>
                                    </li>
                                    <li><a href="#twitter"><i class="fab fa-twitter"></i></a>
                                    </li>
                                    <li><a href="#google"><i class="fab fa-google-plus-g"></i></a>
                                    </li>
                                    <li><a href="#github"><i class="fab fa-github"></i></a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-3 col-6 footer-list-29 mt-md-0 mt-4">
                            <ul>
                                <h6 class="footer-title-29">Quick Links</h6>
                                <li><a href="about.html">About Us</a></li>
                                <li><a href="#services">Services</a></li>
                                <li><a href="#workwithus">Work with us</a></li>
                                <li><a href="contact.html">Contact Us</a></li>
                                <li><a href="#rewards">Rewards</a></li>
                            </ul>
                        </div>
                        <div class="col-lg-2 col-md-3 col-6 ps-lg-5 ps-lg-4 footer-list-29 mt-md-0 mt-4">
                            <ul>
                                <h6 class="footer-title-29">Explore</h6>
                                <li><a href="#blog">Blog Posts</a></li>
                                <li><a href="#privacy">Privacy policy</a></li>
                                <li><a href="#partners">Our Partners</a></li>
                                <li><a href="#license">License & uses</a></li>
                                <li><a href="#events">Events</a></li>
                            </ul>
                        </div>
                        <div class="col-lg-4 col-md-6 col-sm-8 footer-list-29 mt-lg-0 mt-4 ps-lg-5">
                            <h6 class="footer-title-29">Subscribe</h6>
                            <form action="#" class="subscribe" method="post">
                                <input type="email" name="email" placeholder="Email Address" required="" />
                                <button class="btn btn-style mt-2">Subscribe</button>
                            </form>
                            <p class="mt-3">Subscribe to our mailing list and get updates to your email inbox.</p>
                        </div>
                    </div>
                    <p class="copy-footer-29 text-center mt-5 pt-lg-3">© 2021 Tours. All rights reserved. Design
                        by <a href="https://w3layouts.com/" target="_blank">W3Layouts</a></p>
                </div>
            </footer>
        </body>
    );
}
export default Building;
