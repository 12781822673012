import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

function TransactionInvoice() {
    const [invoice, setInvoice] = useState([]);
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        fetch(`https://backendjaes.passiontrials.co.za/monthly_user_payments`, {
            headers: { 'Content-Type': 'application/json' }
        }).then(res => res.json())
            .then(data => {
                console.log(data);
                setInvoice(data);
            })
            .catch(err => setError(err.message));
    }, []);


    const collectionOfInfo = (id) => {
        const selectedInvoice = invoice.find(inv => inv.PayId === id);
        navigate(`/SingleTransaction/${id}`, { state: { invoice: selectedInvoice } });

    };

    return (
        <div className='container'>
            <br />
            <h2>Tenent Payments</h2>
            {error && <p className='error'></p>}
            <table className='payment-table'>
                <thead>
                    <tr>
                        <th>PAYMENT ID</th>
                        <th>TENENT</th>
                        <th>AMOUNT</th>
                        <th>DUE DATE</th>
                        <th>BUILDING NAME</th>
                        <th>Type of Apartment</th>
                        <th>Preferred Room Gender</th>
                        <th>City</th>
                        <th>STATUS</th>
                        <th>ACTION</th>

                    </tr>
                </thead>
                <tbody>
                    {invoice.map(invo => (
                        <tr key={invo.PayId}>
                            <td>#{invo.PayId}</td>
                            <td>
                                <div className='client-info'>
                                    <img src={invo.clientImage || 'https://picsum.photos/200/300?random=40'} alt='client' className='client-image' />
                                    <div>
                                        <strong>{invo.Name} {invo.Surname}</strong><br />
                                        {invo.Email || 'Email'}
                                    </div>
                                </div>
                            </td>
                            <td className='amount'>R{invo.PayAmount}</td>
                            <td className='date'>{invo.PayDate}</td>
                            <td className='amount'>{invo.BuildingName}</td>
                            <td className='amount'>{invo.ApartmentType}</td>
                            <td className='amount'>{invo.ApartmentPreferedGender}</td>
                            <td className='amount'>{invo.BuildingCity}</td>
                            <td className='amount'>
                                <span className={`status ${invo.PaymentStatus.toLowerCase()} `}>
                                    {invo.PaymentStatus}
                                </span>
                            </td>
                            <td className='actions'>
                                <button className='view-button' onClick={() => collectionOfInfo(invo.PayId)}>View</button>
                                <button className='edit-button'>⬇</button>
                            </td>

                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    )

}

export default TransactionInvoice;