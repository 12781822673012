import React, { useRef, useState, useEffect } from 'react';
import { Route, Routes, Link } from 'react-router-dom';
import { useAuth } from './AuthProvider';



// Dummy data for demonstration

function useOutSideAlerter(ref, onOutsideClick) {
    useEffect(() => {
        function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target)) {
                onOutsideClick();

            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref, onOutsideClick]);
}

function AdminDashboard() {
    const [bookings, setBookings] = useState([]);
    const [admin, setAdmin] = useState(null);
    const [payments, setPayments] = useState([]);
    const [nonPaid, setNonPaid] = useState([]);
    const [monthPaid, setMonthPaid] = useState([]);

    const { userId, role } = useAuth();


    const [apartments, setApartments] = useState([]);
    const [customers, setCustomers] = useState([]);
    const [error, setError] = useState('');
    const [Profile, setProfile] = useState({});
    const [showDropdown, setShowDropdown] = useState(false);
    const wrapperRef = useRef(null);

    useOutSideAlerter(wrapperRef, () => setShowDropdown(false));

    useEffect(() => {
        setBookings('');

        setApartments();



        if (userId) {

            fetch(`https://backendjaes.passiontrials.co.za/user_tb`, {
                headers: { 'Content-Type': 'application/json' }
            })
                .then(response => response.json())
                .then(data => {

                    if (data) {
                        console.log("Pre insertion", data, `this is the userId ${userId}`);

                        setCustomers(data);
                    }
                    else {
                        console.log('Cannot find users');

                    }
                })
                .
                catch(error => {
                    console.error('Error', error);
                    setError(error.message);
                });
        }



        setProfile('');
    }, [userId]);

    useEffect(() => {
        if (userId) {


            fetch(`https://backendjaes.passiontrials.co.za/admin_tb/${userId}`, {
                headers: { 'Content-Type': 'application/json' }
            })
                .then(response => response.json())
                .then(data => {
                    if (data) {
                        console.log("Pre insertion", data, `this is the userId ${userId}`);
                        setAdmin(data);
                        console.log("Post insertion", admin);
                    }
                    else {
                        console.log('No admin data found');
                    }
                })
                .catch(error => {
                    console.error('Error', error);
                    setError(error.message);
                });

            paidUsers();
            paidUsersForMonth();
            nonPaidUsers();

        }
    }, [userId]);

    const toggleDropdown = (e) => {
        e.stopPropagation();
        setShowDropdown(!showDropdown);
    };

    const paidUsers = () => {
        fetch('https://backendjaes.passiontrials.co.za/all_paid_users', {
            headers: { 'Content-Type': 'application/json' }
        })
            .then(response => response.json())
            .then(data => {
                if (data) {
                    console.log("Pre insertion", data, `this is the userId ${userId}`);
                    setPayments(data);
                }
                else {
                    console.log('No paid users found');
                }
            })
            .catch(error => {
                console.error('Error', error);
                setError(error.message);
            })
    };

    const paidUsersForMonth = () => {

        fetch('https://backendjaes.passiontrials.co.za/all_paid_users_on_current_month', {
            headers: { 'Content-Type': 'application/json' }
        })
            .then(res => res.json())
            .then(data => {

                if (data) {
                    setMonthPaid(data);
                }
                else {
                    console.log('No users found that paid for the month');
                }

            }).catch(error => {
                console.error('Error', error);
                setError(error.message);
            })
    };

    const nonPaidUsers = () => {

        fetch('https://backendjaes.passiontrials.co.za/non_paid_users_current_month', {
            headers: { 'Content-Type': 'application/json' }
        })
            .then(res => res.json())
            .then(data => {
                if (data) { setNonPaid(data) } else { console.log('No unpaid users found'); }
            })
            .catch(error => {
                console.error('Error', error);
                setError(error.message);
            });

    };

    return (
        <div style={{ padding: '20px' }}>
            <div style={{ display: 'flex', justifyContent: "space-between", alignItems: "center", padding: "20px", }}>
                <h1>Admin Dashboard</h1>
                <div ref={wrapperRef} onClick={toggleDropdown} style={{ display: "flex", alignItems: "center", cursor: "pointer" }}>
                    <img style={{ width: "50px", height: "50px", borderRadius: '50%', marginRight: '10px', objectFit: "cover" }} src={Profile.imageUrl || "https://picsum.photos/200/300?random=30"} alt="Admin" />
                    <span>{admin?.AdminName} {admin?.AdminSurname}</span>
                    {showDropdown && <DropdownMenu role={role} />}
                </div>
            </div>
            <nav>
                <Link to="/bookings">Bookings</Link> |
                <Link to="/transactionInvoice">Payments</Link> |
                <Link to="/adminapartments">Apartments</Link> |
                <Link to="/customers">Customers/Clients</Link>
            </nav>
            <Routes>
                <Route path="/bookings" element={<BookingList bookings={bookings} />} />
                <Route path="/payments" element={<PaymentList payments={payments} />} />
                <Route path="/apartments" element={<ApartmentList apartments={apartments} />} />
                <Route path="/customers" element={<CustomerList customers={customers} />} />
            </Routes>
            {admin ? (
                <div>
                    <h2>Admin Details</h2>
                    <p><strong>ID:</strong> {admin.AdminId}</p>
                    <p><strong>Username:</strong> {admin.AdminUsername}</p>
                    <p><strong>Name:</strong> {admin.AdminName}</p>
                    <p><strong>Surname:</strong> {admin.AdminSurname}</p>
                    <p><strong>Email:</strong> {admin.AdminEmail}</p>
                    <p><strong>Role:</strong> {admin.Role}</p>
                    <p><strong>Account Created:</strong> {admin.AccountCreatedDate}</p>
                    <p><strong>Last Login:</strong> {admin.AdminLastLogin}</p>
                </div>
            ) : (
                <p>Loading admin data...</p>
            )}


            <h1>All register users</h1>
            {customers.length > 0 ? (


                customers.map(customer => (

                    <div >

                        <div key={customer.UserId}> <p>{customer.Name} -{customer.Surname}</p></div>
                    </div>
                ))
            ) : (
                <p>No customers found</p>
            )}

            <h1>List of paid users</h1>
            {payments.length > 0 ? (
                payments.map(pay => (
                    <div>
                        <div key={pay.UserId}>
                            <p>{pay.Name} -{pay.Surname}</p>
                        </div>
                    </div>
                ))


            ) : (
                <p>No paid users found </p>
            )}

            <h1>List non-paid users on current month</h1>
            {nonPaid.length > 0 ? (
                nonPaid.map(pay => (
                    <div>
                        <div key={pay.UserId}>
                            <p>{pay.Name} -{pay.Surname}</p>
                        </div>
                    </div>
                ))


            ) : (
                <p>No unpaid users found for the month </p>
            )}

            <h1>List of paid users for the month</h1>
            {monthPaid.length > 0 ? (
                monthPaid.map(pay => (
                    <div>
                        <div key={pay.UserId}>
                            <p>{pay.Name} -{pay.Surname}</p>
                        </div>
                    </div>
                ))


            ) : (
                <p>No paid users found for the month </p>
            )}

            <h1>List of users invoice</h1>

        </div >



    );
}

function BookingList({ bookings }) {
    return (
        <div>
            <h2>Bookings</h2>
            {bookings.map(booking => (
                <div key={booking.id}>
                    <p>{booking.location} - {booking.date}</p>
                </div>
            ))}
        </div>
    );
}

function PaymentList({ payments }) {
    return (
        <div>
            <h2>Payments</h2>
            {payments.map(payment => (
                <div key={payment.id}>
                    <p>Amount: {payment.amount} - Status: {payment.status} - Date: {payment.date}</p>
                </div>
            ))}
        </div>
    );
}

function ApartmentList({ apartments }) {
    return (
        <div>
            <h2>Apartments</h2>
            {

                apartments.map(apartment => (
                    <div key={apartment.id}>
                        <p>{apartment.name} - Status: {apartment.status}</p>
                    </div>
                ))}
        </div>
    );
}

function CustomerList({ customers }) {
    console.log("CustomerList received customers: ", customers); // Debugging log

    return (
        <div>
            <h2>Customers/Clients</h2>
            {
                customers.length > 0 ? (

                    customers.map(customer => (
                        <div key={customer.UserId}>
                            <p>{customer.Name}</p>
                        </div>
                    ))
                ) : (
                    <p>No customers found</p>
                )}
        </div>
    );
}

function DropdownMenu({ role }) {
    return (
        <div style={{ position: "absolute", right: 4, top: "170px", backgoundColor: "ffffff", boxShadow: "0 4px 8px rgba(0,0,0,0.1)", borderRadius: "8px", width: "200px", zIndex: 1000 }}>
            <Link style={{ display: "block", padding: "10px", textDecoration: "none", color: "#333" }} to="/profile">Admin Profile</Link>
            {role == 'Super Admin' && (
                <Link style={{ display: "block", padding: "10px", textDecoration: "none", color: "#333" }} to="/manage">Management</Link>
            )}
            <Link style={{ display: "block", padding: "10px", textDecoration: "none", color: "#333" }} to="/booking">Bookings</Link>
            <Link style={{ display: "block", padding: "10px", textDecoration: "none", color: "#333" }} to="/transactionInvoice">Transactions</Link>

            <Link style={{ display: "block", padding: "10px", textDecoration: "none", color: "#333" }} to="/setting">Settings</Link>
            <Link style={{ display: "block", padding: "10px", textDecoration: "none", color: "#333" }} to="/">Logout</Link>



        </div>
    )
}
export default AdminDashboard;
