import React, { useState } from 'react';
import './RegistrationModal.css'; // Make sure this CSS file is properly imported to style the modal
import { useNavigate } from 'react-router-dom';
import LoginModal from './LoginModal.js'; // Import the Login Modal

function RegistrationModal({ onSuccess, onClose, date, apartment, apartmentandbuilding, totalAmount, serviceFee, chosenPlan, nextPaymentDate }) {
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        Name: '',
        Surname: '',
        Email: '',
        PhoneNumber: '',
        Password: '',
        ConfirmPassword: '',
        IdNumber: '',
        Gender: '',
        DateOfBirth: '',
    });
    const [faceImage, setFaceImage] = useState(null); // State to hold face image
    const [idImage, setIdImage] = useState(null); // State to hold ID image
    const [loading, setLoading] = useState(false);
    const [isLoginModalOpen, setIsLoginModalOpen] = useState(false); // State to manage login modal

    // Handle input changes
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });

        if (name === 'IdNumber' && value.length === 13) {
            const gender = handleGender(value);
            const dateOfBirth = handleDateOfBirth(value);

            setFormData({
                ...formData,
                [name]: value,
                Gender: gender,
                DateOfBirth: dateOfBirth
            });
        }
    };

    // Function to extract gender from ID number
    const handleGender = (idNumber) => {
        const genderCode = idNumber.slice(6, 10);
        return parseInt(genderCode, 10) >= 5000 ? 'Male' : 'Female';
    };

    // Function to extract date of birth from ID number
    const handleDateOfBirth = (idNumber) => {
        const year = idNumber.slice(0, 2);
        const month = idNumber.slice(2, 4);
        const day = idNumber.slice(4, 6);
        const fullYear = parseInt(year, 10) >= 0 && parseInt(year, 10) <= 22 ? `20${year}` : `19${year}`;
        return `${fullYear}-${month}-${day}`;
    };

    // Handle image file selection
    const handleImageChange = (e) => {
        if (e.target.name === 'FaceImage') {
            setFaceImage(e.target.files[0]); // Set face image
        } else if (e.target.name === 'ID_Image') {
            setIdImage(e.target.files[0]); // Set ID image
        }
    };

    // Handle form submission
    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true); // Start loading

        if (formData.Password !== formData.ConfirmPassword) {
            alert("Passwords do not match!");
            setLoading(false); // Stop loading
            return;
        }

        const formDataToSend = new FormData();
        Object.keys(formData).forEach((key) => {
            formDataToSend.append(key, formData[key]);
        });

        if (faceImage) {
            formDataToSend.append('FaceImage', faceImage);
        }

        if (idImage) {
            formDataToSend.append('ID_Image', idImage);
        }

        try {
            const response = await fetch('https://backendjaes.passiontrials.co.za/user_register', {
                method: 'POST',
                body: formDataToSend
            });

            const data = await response.json();
            if (response.ok) {
                alert(data.message);
                // Show login modal after successful registration
                setIsLoginModalOpen(true);
            } else {
                alert('Registration failed: ' + data.message);
            }
        } catch (error) {
            console.error('Error during registration:', error);
            alert('An error occurred during registration.');
        } finally {
            setLoading(false); // Stop loading after process is complete
        }
    };

    const handleLoginSuccess = () => {
        // After successful login, navigate to YocoPayment page
        navigate('/YocoPayment', { state: { date, apartment, apartmentandbuilding, totalAmount, serviceFee, chosenPlan, nextPaymentDate } });
    };

    return (
        <div className="modal-overlay">
            <div className="modal-content">
                <h2>Register</h2>
                <form onSubmit={handleSubmit} encType="multipart/form-data">
                    {/* Group First and Last Name */}
                    <div className="form-group">
                        <input
                            type="text"
                            name="Name"
                            value={formData.Name}
                            onChange={handleChange}
                            placeholder="First Name"
                            required
                        />
                        <input
                            type="text"
                            name="Surname"
                            value={formData.Surname}
                            onChange={handleChange}
                            placeholder="Last Name"
                            required
                        />
                    </div>

                    {/* Group Email and Phone Number */}
                    <div className="form-group">
                        <input
                            type="email"
                            name="Email"
                            value={formData.Email}
                            onChange={handleChange}
                            placeholder="Email"
                            required
                        />
                        <input
                            type="text"
                            name="PhoneNumber"
                            value={formData.PhoneNumber}
                            onChange={handleChange}
                            placeholder="Phone Number"
                            required
                        />
                    </div>

                    {/* ID Number */}
                    <div className="form-group">
                        <input
                            type="text"
                            name="IdNumber"
                            value={formData.IdNumber}
                            onChange={handleChange}
                            placeholder="ID Number (13 digits)"
                            maxLength="13"
                            required
                        />
                    </div>

                    {/* Gender and Date of Birth */}
                    <div className="form-group">
                        <input
                            type="text"
                            name="Gender"
                            value={formData.Gender}
                            placeholder="Gender"
                            readOnly
                        />
                        <input
                            type="text"
                            name="DateOfBirth"
                            value={formData.DateOfBirth}
                            placeholder="Date of Birth"
                            readOnly
                        />
                    </div>

                    {/* Group Password and Confirm Password */}
                    <div className="form-group">
                        <input
                            type="password"
                            name="Password"
                            value={formData.Password}
                            onChange={handleChange}
                            placeholder="Password"
                            required
                        />
                        <input
                            type="password"
                            name="ConfirmPassword"
                            value={formData.ConfirmPassword}
                            onChange={handleChange}
                            placeholder="Confirm Password"
                            required
                        />
                    </div>

                    {/* Add file input for uploading face image */}
                    <div className="form-group">
                        <label>Upload Face Photo:</label>
                        <input
                            type="file"
                            name="FaceImage"
                            accept="image/*"
                            onChange={handleImageChange}
                            required
                        />
                    </div>

                    {/* Add file input for uploading ID image */}
                    <div className="form-group">
                        <label>Upload ID Image:</label>
                        <input
                            type="file"
                            name="ID_Image"
                            accept="image/*"
                            onChange={handleImageChange}
                            required
                        />
                    </div>

                    <div className="form-group">
                        <button type="button" onClick={onClose}>Cancel</button>
                        <button type="submit" disabled={loading}>
                            {loading ? 'Registering...' : 'Register'}
                        </button>
                    </div>

                    <div className="form-group">

                        <button type="button" onClick={() => setIsLoginModalOpen(true)}>Login</button> {/* Add this button to open login modal */}
                    </div>
                </form>

                {/* Show the login modal after clicking the Login button */}
                {isLoginModalOpen && (
                    <LoginModal
                        onClose={() => setIsLoginModalOpen(false)}
                        onSuccess={handleLoginSuccess}
                        date={date}
                        apartment={apartment}
                        apartmentandbuilding={apartmentandbuilding}
                        totalAmount={totalAmount}
                        serviceFee={serviceFee}
                        chosenPlan={chosenPlan}
                        nextPaymentDate={nextPaymentDate}
                    />
                )}
            </div>
        </div>
    );
}

export default RegistrationModal;
