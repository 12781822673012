import React, { useState, useEffect } from 'react';

const TransportAdmin = () => {
    const [transports, setTransports] = useState([]); // To store all transport records
    const [filteredTransports, setFilteredTransports] = useState([]); // To store filtered transport records
    const [filterInstitution, setFilterInstitution] = useState(""); // Filter by institution
    const [filterBuilding, setFilterBuilding] = useState(""); // Filter by building
    const [filterDirection, setFilterDirection] = useState(""); // Filter by direction

    // Get today's local date in YYYY-MM-DD format
    const todayDate = new Date();
    const formattedTodayDate = todayDate.toISOString().split('T')[0];
    console.log("Today's Date (Local):", formattedTodayDate); // Log today's date for comparison
    const [buildings, setBuildings] = useState([]); // State to hold the building data
    const [selectedBuilding, setSelectedBuilding] = useState(""); // State to hold the selected building

    // Function to fetch building data from the backend
    const buildingInfo = () => {
        fetch(`https://backendjaes.passiontrials.co.za/new_building_tb`)
            .then(res => res.json())
            .then(info => {
                setBuildings(info); // Store the building data in state
                console.log('Fetched building data:', info);
            })
            .catch(error => {
                console.log('An error occurred', error);
            });
    };

    // Fetch the building data when the component mounts
    useEffect(() => {
        buildingInfo();
    }, []);

    // Handle building selection
    const handleBuildingChange = (e) => {
        setSelectedBuilding(e.target.value);
    };
    // Fetch all transport data from the backend
    useEffect(() => {
        fetch('https://backendjaes.passiontrials.co.za/api/transportadmin')
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                console.log("Fetched Transport Data:", data); // Log the fetched transport data
                setTransports(data);
                filterTodayData(data); // Initially set filtered data to today's data
            })
            .catch(error => console.error('Error fetching transport data:', error));
    }, []);

    // Format date in display as "Tues, 2 July"
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const options = { weekday: 'short', day: 'numeric', month: 'short' };
        return date.toLocaleDateString('en-US', options);
    };

    // Convert UTC transport date to local date in YYYY-MM-DD format
    const getLocalDateFormatted = (dateString) => {
        const date = new Date(dateString);
        const localYear = date.getFullYear();
        const localMonth = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
        const localDay = String(date.getDate()).padStart(2, '0');

        return `${localYear}-${localMonth}-${localDay}`;
    };

    // Filter today's transport data
    const filterTodayData = (data) => {
        const filtered = data.filter(transport => {
            // Convert transport_date from UTC to local date
            const transportDateFormatted = getLocalDateFormatted(transport.transport_date);
            console.log(`Transport Date (Record): ${transport.transport_date}, Local Formatted: ${transportDateFormatted}`);

            // Date match using today's local date
            const dateMatch = transportDateFormatted === formattedTodayDate;
            console.log(`Date Match: ${dateMatch ? 'Yes' : 'No'}`); // Log if the date matches today

            // Case-insensitive matching for institution, building, and direction
            const institutionMatch = filterInstitution ? transport.institution.toLowerCase() === filterInstitution.toLowerCase() : true;
            const buildingMatch = filterBuilding ? transport.building_name.toLowerCase().includes(filterBuilding.toLowerCase()) : true;
            const directionMatch = filterDirection ? transport.direction.toLowerCase() === filterDirection.toLowerCase() : true;

            return dateMatch && institutionMatch && buildingMatch && directionMatch;
        });

        setFilteredTransports(filtered); // Update filtered transports with today's data
    };

    // Handle clearing all filters
    const clearFilters = () => {
        setFilterInstitution("");
        setFilterBuilding("");
        setFilterDirection("");
        filterTodayData(transports); // Reset to today's data
    };

    // Apply filters on user input change
    const handleFilter = () => {
        filterTodayData(transports); // Filter with the updated filter inputs
    };

    return (
        <div style={{ padding: '20px', marginTop: '109px' }}>
            <h2 style={{ textAlign: 'center' }}>Today's Booked Transports</h2>

            {/* Filters */}
            <div
                style={{
                    marginBottom: '20px',
                    display: 'flex',
                    justifyContent: 'center', // Center the filters horizontally
                    alignItems: 'center',
                    gap: '20px',
                }}
            >
                {/* Filter by Institution */}
                <div>
                    <select
                        id="institutionFilter"
                        value={filterInstitution}
                        onChange={e => setFilterInstitution(e.target.value)}
                    >
                        <option value="">All Institutions</option>
                        <option value="Ukzn Howard">Ukzn Howard</option>
                        <option value="UKZN Medical">UKZN Medical</option>
                        <option value="Richfield Campus">Richfield Campus</option>
                        <option value="DUT Steve Biko">DUT Steve Biko</option>
                        <option value="Umbilo College">Umbilo College</option>
                        <option value="Coastal College">Coastal College</option>
                    </select>
                </div>

                {/* Filter by Building */}
                <div>
                    <select
                        id="building"
                        value={selectedBuilding}
                        onChange={handleBuildingChange}
                    >
                        <option value="">Select a building</option>
                        {buildings.map((building, index) => (
                            <option key={index} value={building.BuildingName}>
                                {building.BuildingName} (Zone: {building.Zone})
                            </option>
                        ))}
                    </select>
                </div>

                {/* Filter by Direction */}
                <div>
                    <select
                        id="directionFilter"
                        value={filterDirection}
                        onChange={e => setFilterDirection(e.target.value)}
                    >
                        <option value="">All Directions</option>
                        <option value="To Home">To Home</option>
                        <option value="To Institution">To Institution</option>
                    </select>
                </div>

                {/* Filter Buttons */}
                <div style={{ display: 'flex', gap: '10px' }}>
                    <button onClick={handleFilter}>Apply Filters</button>
                    <button onClick={clearFilters}>Clear Filters</button>
                </div>
            </div>

            {/* Display transport table */}
            {filteredTransports.length > 0 ? (
                <div style={{ marginTop: '20px' }}>
                    <table
                        style={{
                            width: '100%',
                            borderCollapse: 'collapse',
                            borderRadius: '5px',
                            overflow: 'hidden',
                        }}
                    >
                        <thead>
                            <tr style={{ backgroundColor: '#f8f8f8', textAlign: 'left' }}>
                                <th style={{ padding: '10px' }}>Time</th>
                                <th style={{ padding: '10px' }}>Date</th>
                                <th style={{ padding: '10px' }}>Building</th>
                                <th style={{ padding: '10px' }}>Institution</th>
                                <th style={{ padding: '10px' }}>Direction</th>
                                <th style={{ padding: '10px' }}>Transport Time</th>
                            </tr>
                        </thead>
                        <tbody>
                            {filteredTransports.map((transport, index) => (
                                <tr key={index} style={{ borderBottom: '1px solid #ccc' }}>
                                    <td style={{ padding: '10px' }}>
                                        {new Date(transport.transport_date).toLocaleTimeString()}
                                    </td>
                                    <td style={{ padding: '10px' }}>{formatDate(transport.transport_date)}</td>
                                    <td style={{ padding: '10px' }}>{transport.building_name}</td>
                                    <td style={{ padding: '10px' }}>{transport.institution}</td>
                                    <td style={{ padding: '10px' }}>{transport.direction}</td>
                                    <td style={{ padding: '10px' }}>{transport.transport_time}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            ) : (
                <p>No transport data available for today.</p>
            )}
        </div>

    );
}

export default TransportAdmin;
